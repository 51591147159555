.table-container {
    width: 100%;
    overflow: overlay; // Adds a scrollbar when the table overflows
    padding: 1rem;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); // Adds a shadow to the table
    border: 1px solid #6681c6; // Adds a border around the table

    table {
       width: 100%;
       border-collapse: collapse; // Collapses borders into a single border
       border-spacing: 0; // Removes default spacing between cells
       border-radius: 20px !important; // Rounds the corners of the table

       th, td {
         padding: 15px; // Adds padding inside cells
         text-align: left; // Aligns text to the left
         border-bottom: 1px solid #ddd; // Adds a bottom border to each cell
       }
   
       th {
         background-color: #062880; // Background color for headers
         color: white; // Text color for headers
         font-weight: 700; // Makes header text bold   
       }

       thead {
        th:first-child {
          border-top-left-radius: 20px;
        }
    
        th:last-child {
          border-top-right-radius: 20px;
        }
      }

       tr:nth-child(even) {
         background-color: #f2f2f2; // Alternating row colors for better readability
       }
   
       tr:hover {
         background-color: #ddd; // Highlights row on hover
       }

       .row-title{
        font-weight: 500;
        font-size: 1.15rem;
       }
    }
   }

@media screen and (max-width: 768px){
  .table-container {
    padding: 0.5rem; // Reduces padding on smaller screens
    border-radius: 10px; // Rounds the corners of the table
    .table {
      th, td {
        padding: 10px; // Reduces padding inside cells
      }
      .row-title{
        font-size: 1rem; // Reduces font size for row titles
        font-weight: 400;
      }
    }
}
}