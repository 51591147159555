* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", sans-serif;
  background-color:#e5e5e5 !important;
  scroll-behavior: smooth;
}

.overflow-scroll-hidden {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

.green-overflow-scroll {
  overflow-y: scroll;
  scrollbar-color: rgb(57, 224, 164) transparent;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(57, 224, 164);
    border-radius: 20px;
    border: 0px solid transparent;
  }
}

negative {
  font-weight: 900;
  color: #fd187e;
}

positive {
  font-weight: 900;
  color: #39e0a4;
}

neutral {
  font-weight: 900;
  color: #FEAF38;
}

.loader {
  display: flex;
  line-height: normal;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  width: 95%;
}

a.canvasjs-chart-credit {
  display: none;
}

.apexcharts-text tspan {
  text-transform: uppercase;
}

span.time {
  text-transform: uppercase;
}
