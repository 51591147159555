.register-page {
  min-height: 90vh;
  padding-top: 2rem;
  padding-bottom: 2rem;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // background: linear-gradient(whitesmoke 0%, #eaeafa 100%);
  background: white;

  // .register-background-image {
  //   position: absolute;
  //   z-index: 1;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   object-fit: cover;
  //   object-position: top;
  // }
  .register-background-image {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    // height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
      animation: moveUp 30s linear infinite; /* Apply the animation to the img */
    }
  }

  @keyframes moveUp {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-10%);
    }
  }
  /* Modal Backdrop */
  // Black backdrop like tint
  .modal-backdrop {
    position: absolute;
    background-color: white;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 3;
    filter: opacity(0.7);
  }

  .register-form-card {
    z-index: 5;
    // color: white;
    background-color: white;
    padding: 1.5rem;
    padding-top: 3rem;
    border-radius: 2rem;
    width: 70%;

    @media (min-width: 768px) {
      width: 40%;
    }

    .register-title {
      font-family: "DM Sans", sans-serif;
      font-size: 1.5rem; /* text-3xl */
      // display: flex; /* flex */
      // align-items: center; /* items-center */
      // justify-content: center; /* justify-center */
      // gap: 1rem; /* gap-4 */
      text-align: left; 

      &:hover {
        color: #93c5fd; /* hover:text-blue-300 */
      }
    }

    .icon {
      font-size: 2.25rem; /* text-4xl */
    }

    .register-form {
      margin-top: 1.25rem; /* mt-5 */
      padding-top: 0.7rem; /* py-6 */
      padding-bottom: 1.5rem; /* py-6 */
      display: flex; /* flex */
      flex-direction: column; /* flex-col */
      align-items: center; /* items-center */
      gap: 1rem; /* gap-4 */
    }

    .input-field {
      padding: 0.5rem; /* p-2 */
      // border-radius: 0.5rem; /* rounded-lg */
      width: 100%; /* w-full */
      border: none;
      border-bottom: 2px solid grey; /* border-2 border-blue-300 */

      &:hover {
        border-color: #1e3a8a; /* hover:border-blue-800 */
      }
    }

    .terms {
      width: 100%;
      color: #BEBCBC;
      text-align: left;
      padding: 0.5rem 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;
    }

    .register-button {
      width: 80%; 
      color: #fff;
      padding: 0.5rem 1.5rem;
      border: #FE5F55;
      // border-radius: 1rem;
      // background: linear-gradient(270deg, #fe7f77 0%, #fe5f55 100%);
      background: #FE5F55;
      box-shadow: 0px 15px 30px 0px rgba(20, 102, 204, 0.16);

      &:hover {
        background: linear-gradient(180deg, #fe7f77 0%, #fe5f55 100%);
      }
    }

    .error-message {
      color: #ef4444; /* text-red-500 */
      text-align: center; /* text-center */
    }

    .success-message {
      color: #10b981; /* text-green-500 */
      text-align: center; /* text-center */
    }

    .redirect-login {
      font-family: "DM Sans", sans-serif;
      color: #BEBCBC;
      padding-top: 1rem;
      padding-bottom: 1rem;
      text-align: center;
    }

    .animate-spin {
      animation: spin 1s linear infinite;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
@media (max-width: 768px) {
  .register-background-image {
    height: 120vh;
  }
  .register-page .register-form-card .register-title {
    display: contents;
  }
  .register-page .register-form-card {
    width: 80%;
  }  
}
