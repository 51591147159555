.Candle-Stick-Pattern {

    padding: 10px;
    padding-top: 0px;
    width: 100%;

    .white-box {
        background: #fff;
        height: 300px;
        overflow-y: scroll;
        width: 100%;
        padding: 10px;
        border-radius: 6px;

        scrollbar-color: rgb(57, 224, 164) transparent; //not used
        scrollbar-width: thin; //not used

        &::-webkit-scrollbar {
            width: 10px;
        }

        &::-webkit-scrollbar-track {
            background: transparent; //not used
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgb(57, 224, 164);
            border-radius: 20px;
            border: 0px solid transparent; //not used
        }



        .heading-text{
            margin-bottom: 15px;
            h6 {
                text-align: center;
                font-weight: 500;
                font-family: "Open Sans", sans-serif;
                font-size: 14px;
            }
        }

        .candle-formed-on {
            text-align: center;
            margin-bottom: 10px;

            p {
                margin: 0;
                font-size: 15px;
                font-family: "Open Sans", sans-serif;
                font-weight: 600;
            }
        }

        .open-high-low-close {
            margin-bottom: 10px;

            p {
                font-size: 14px;
                margin: 0;
                text-align: center;
                font-family: "Open Sans", sans-serif;

            }
        }

        .pattern-formed {
            margin-bottom: 10px;


            p {
                font-size: 16px;
                margin: 0;
                text-align: center;
                font-family: "Open Sans", sans-serif;

                span {
                    font-weight: 600;
                }
            }
        }

        .pattern-info-bullet-points {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;


            ul {
                list-style-position: inside;

                li {
                    font-size: 14px;
                    margin-bottom: 4px !important;
                    font-family: "Open Sans", sans-serif;
                }
            }

        }
    }


}



@media only screen and (max-width: 991px) {

    .Candle-Stick-Pattern {

        .white-box {

            .heading-text{
                h6 {
                    font-size: 14px;
                }
            }

            .candle-formed-on {
                p {
                    font-size: 14px;
                }
            }

            .open-high-low-close {
                p {
                    font-size: 13px;
                }
            }

            .pattern-formed {
                p {
                    font-size: 14px;
                }
            }

            .pattern-info-bullet-points {
                ul {
                    li {
                        font-size: 13px;
                    }
                }

            }
        }


    }

}