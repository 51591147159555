.trending-price-change-card {
  height: 16rem;
  margin: 10px;
  background-color: var(--Fabric-Color-Pallete, #fff);
  filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.34));
  padding: 0.5rem;
  border-radius: 0.5rem;
  // margin-bottom: 1rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  text-align: center; /* Center text inside the card */
  display: flex;
  flex-direction: column;

  .no-data{
    // background-color: #f8d9dc; /* Light red background for emphasis */
    color: #721c24; /* Dark red text color for contrast */
    // border: 1px solid #f5c6cb; /* Light red border */
    border-radius: 8px; /* Rounded corners */
    padding: 15px; /* Space inside the box */
    font-size: 13px; /* Increase font size */
    font-weight: bold; /* Bold text */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    width: fit-content;
    margin-inline: auto;
  }

  .title {
    font-size: 1.4rem;
    font-weight: 700;
  }

  .price-grid {
    margin-top: 0.5rem;
    display: grid;
    // grid-template-columns: 1fr 1fr;
    gap: 0.2rem;
    font-size: 0.875rem;
    overflow-y: auto;
    // max-height: 8.5rem;
    max-height: 85%;
    position: relative;

    .text-red {
      color: red;
    }

    .text-green {
      color: green;
    }

    .header {
      position: sticky;
      top: 0;
      font-weight: 700;
      margin: 0 0.5rem;
      border-bottom: 1px solid #ccc;
      background-color: var(--Fabric-Color-Pallete, #fff);
    }

    .value {
      font-weight: 500;
      margin: 0 0.5rem;
    }
  }
}

@media (max-width: 768px){
  .trending-price-change-card{
    height: 230px !important;
  }
}