.statergy-news-split{
    width:100%;
    display: flex;
    .statergy-pane{
        width:70%;
        background: linear-gradient(whitesmoke 0%, #eaeafa 100%);
        .statergy-prediction{
            display: flex;
            gap:2rem;
            flex-direction: column;
            margin:2rem 5rem;
            background-color: #ffffff;
            padding:0 4rem;
            border-radius: 20px;
            box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
            .title{
                font-size: 1.5rem;
                font-weight: 600;
                color: #000000;
                padding: 1rem;
            }
            img{
                display: flex;
                width: 100%;
                border-radius: 20px;
            }
        }
        .text-summary{
            max-width: 80%;
            .heading{
                justify-content: right;
                display:flex;
                align-items: center;
                gap:2rem;
            }
            .text{
                font-size: 1.2rem;
                font-weight: 400;
                color: rgba(0,0,0,0.8);
                padding: 1rem;
            }
            .text-hidden {
                max-height: 0;
                overflow: hidden;
                transition: max-height 1s ease-out;
              }
              .text-visible {
                max-height: 500px; /* Adjust based on your content size */
                transition: max-height 1s ease-in;
              }
        }
        .table-split{
            display: grid;
            grid-template-columns: 1fr 1fr;
            // display: flex;
            // justify-content: space-between;
            margin: 1rem 5rem;
            // max-height: 500px;
            gap:1rem;
            height: 80vh;
            .historical-table{
                overflow-y: scroll;
            }
        }
        .chart-section {
            width: 100%;

            .chart-tabs {
                display: flex;
                justify-content: center;
                gap: 1rem;
                margin: 1rem 0;
                .tab-button {
                    padding: 0.5rem 1rem;
                    border-radius: 5px;
                    cursor: pointer;
                    background-color: #2d4890;
                    color: white;
                    font-size: 1rem;
                    font-weight: 500;
                }
                .active {
                    background-color: #ffffff;
                    color: #2d4890;
                }
            }
            .chart-content {
              padding: 1rem 2rem;
              .chart-loading {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 2rem 0;
                font-size: 1.5rem;
                font-weight: 700;
              }
              .chart-iframe {
                padding: 1rem;
                width: 100%;
                height: 70vh;
              } 
            }
        }
    }
    .news-pane{
        width:30%;
        height: 105rem;
        background-color: #E4E7F0;
        .title{
            font-size: 1.5rem;
            font-weight: 600;
            color: #000000;
            padding: 2rem;
        }
        .news-card-contaier{
            overflow-y: scroll;
            // max-height: 175%
        }
        .news-item{
            background-color: #ffffff;
            padding: 1rem;
            margin: 1rem;
            border-radius:10px;
        }
        .news-title{
            margin-bottom: 1rem;
        }
        .news-published{
            font-size: 1rem;
            font-weight: 400;
            color: rgba(0,0,0,0.6);
            margin-bottom: 1rem;
        }
    }

}
@media (max-width: 769px) {

    .statergy-news-split .statergy-pane {
      width: 100%;
    }
  
    .backtesting-table {
      height: 40rem;
      padding: 10px 10px !important;
    }
  
    .backtesting-table table td {
      padding: 5px 10px !important;
    }
  
    .backtesting-table .title {
      padding: 10px;
      text-align: center;
    }
  
    .historical-table {
      overflow-y: scroll;
      height: 40rem;
      padding: 10px 10px !important;
    }
  
    .historical-table th,
    .historical-table td {
      font-size: 14px !important;
      padding: 15px 5px !important;
    }
  
    .historical-table .title {
      padding: 10px;
      text-align: center !important;
    }
  
    .historical-table table th {
      padding: 15px 0px !important;
    }
  
    .historical-table table td {
      padding: 15px 5px !important;
    }
  
    .statergy-pane .text-summary .heading {
      margin-bottom: 10px !important;
    }
  
    .table-split {
      display: flex !important;
      flex-direction: column !important;
      justify-content:  space-between !important;
      margin: 1rem 2.5rem !important;
      max-height: 1000px !important;
      gap: 2rem;
    }
  
    .no-signal-text {
      text-align: center;
    }
  
    .statergy-prediction {
      gap: 1rem !important;
      padding: 0 3rem !important;
      margin: 2rem 2.5rem !important;
    }
    .statergy-prediction .title {
      font-size: 1rem !important;
      font-weight: 500 !important;
      color: #000000;
      margin-top: 1rem !important;
      padding: 0 !important;
    }
    .statergy-prediction .heading {
      gap: 1rem !important;
    }
  
    .statergy-prediction .icon-handler {
      width: 40px;
    }
    .about-strategy-text {
      font-size: 15px !important;
      width: 12rem;
    }
    .container {
      --bs-gutter-x: 0rem !important;
      position: relative;
    }
  
    .toggle-button {
      position: absolute;
      right: 0;
      padding: 10px 20px;
      background-color: #2d4890;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      z-index: 9; 
    }
  
    .news-pane {
      height: 120rem !important;
      width: 60% !important;
      position: absolute;
      right: 0;
      background-color: #E4E7F0;
      box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
      transition: transform 0.3s ease;
      transform: translateX(100%); 
    }
  
    .news-pane.slide-in {
      transform: translateX(0); 
    }
  
    .news-pane.slide-out {
      display: none;
      transform: translateX(100%) !important; 
    }
    .news-pane .title {
      font-size: 1.5rem;
      font-weight: 600;
      color: #000000;
      padding: 2rem;
    }
    .news-card-contaier {
      padding: 1rem;
      overflow-y: scroll;
      max-height: 100%;
    }
    .news-item {
      background-color: #ffffff;
      padding: 1rem;
      margin: 1rem;
      border-radius: 10px;
    }
  
    .news-title {
      margin-bottom: 1rem;
    }
  
    .news-published {
      font-size: 1rem;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      margin-bottom: 1rem;
    }
  }
  