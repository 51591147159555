.trending-event-card {
    background-color: var(--Fabric-Color-Pallete, #fff);
    // filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.34));
    border: #000 1px solid;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;

    .card-title {
      color: #304c94;
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .name {
        font-size: 1.2rem;
        font-weight: 700;
        padding-right: 0.5rem;
        // border-right: 1px solid #000;
      }
      .ticker {
        font-size: 0.8rem;
        font-weight: 500;
        opacity: 0.7;
      }
    }

    .events {
      .event {
        margin: 0.5rem 0;

        .event-title {
          font-size: 1rem;
          font-weight: 700;
          padding-bottom: 0.5rem;
        }
        .event-subtitle {
          font-size: 1rem;
          font-weight: 400;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .event-subtitle:hover {
          -webkit-line-clamp: unset;
        }
      }
    }
  }