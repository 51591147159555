.landingv2-nav {
  width: 100%;
  background: linear-gradient(whitesmoke 0%, #eaeafa 100%);
  // background:rgba(200, 200, 200, 0.21);
  border-bottom: 1px solid #3b3b3b;
  position: relative;
  z-index: 1050;
  padding: 0.5rem 0.5rem;
  
  .nav-flex {
    padding: 0.5rem 2rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
    position: relative;

    .main-logo img {
      height: 8vh;
      aspect-ratio: initial;
    }

    .links {
      z-index: 100;
      position: absolute;
      top: 100%;
      left: 0;
      background-color: white;
      width: 100%;
      border-radius: 1rem;
      padding: 1rem 5%;
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
      list-style: none;

      li a {
        padding: 0.2rem 0.5rem;
        border-radius: 1rem;
        text-decoration: none;
        color: var(--brand-brand-fabric-blue, #2d4890);
        // color:#ffffff;
        font-family: "Open Sans", sans-serif;
        font-size: 1rem;
        font-weight: 400;
      }

      li:hover a {
        background-color: var(--brand-brand-fabric-blue, rgba(33, 45, 76, 0.466));
        color: white;
      }

      li.active a {
        background-color: var(--brand-brand-fabric-blue, #2d4890);
        color: white;
      }
    }

    .links-hide-mobile {
      display: none;
    }

    .mobile-only{
      color: #fff;
      padding: 0.5rem 1.5rem;
      border: #fe5f55;
      border-radius: 1rem;
      background: linear-gradient(270deg, #fe7f77 0%, #fe5f55 100%);
      box-shadow: 0px 15px 30px 0px rgba(20, 102, 204, 0.16);
    }

    .buttons {
      display: none;
    }

    .mobile-button {
      height: 2rem;
      aspect-ratio: 1/1;
      margin: 1rem;

      outline: none;
      border: none;
    }
  }

  #monitoring-dashboard button {
    z-index: 10;
    position: fixed;
    right: -20px;
    transition: 0.3s;
    // padding: 15px;
    width: 80px;
    top: 11.5rem;
    text-decoration: none;
    font-size: 10px;
    font-weight: bold;
    background-color: #062880 ;
    color: white;
    text-transform: capitalize;
    border-radius: 8px 8px 8px 8px;
    vertical-align: top;
    transform: rotate(7deg);
    -ms-transform: rotate(90deg); /* IE 9 */
    -moz-transform: rotate(90deg); /* Firefox */
    -webkit-transform: rotate(90deg); /* Safari and Chrome */
    -o-transform: rotate(90deg);
  }
  
  #monitoring-dashboard button:hover{
    right: 0px;
    transform: rotate(0deg);
    -ms-transform: rotate(0deg); /* IE 9 */
    -moz-transform: rotate(0deg); /* Firefox */
    -webkit-transform: rotate(0deg); /* Safari and Chrome */
    -o-transform: rotate(0deg);
  }
  }



@media (min-width: 768px) {
  .landingv2-nav {
    width: 100%;
    // background:rgb(43, 43, 43);
    margin:0 auto;
    // border-radius:20px;
    backdrop-filter: blur(10px);
    position: sticky;
    top: 0;
    bottom: 0;

    .nav-flex {
      display: flex;
      align-items: center;
      gap: 1rem;
      justify-content: space-between;
      

      .main-logo img {
        height: 8vh;
        aspect-ratio: initial;
      }

      .links {
        flex-direction: row;
        width: auto;
        position: inherit;
        padding: 0;
        background-color: unset;
      }

      .links-hide-mobile {
        display: flex;
      }
      .mobile-only {
          display: none;
      }

      .buttons {
        display: flex;
        align-items: center;
        gap: 1rem;

        button {
          color: #fff;
          padding: 0.5rem 1.5rem;
          border: #fe5f55;
          border-radius: 1rem;
          background: linear-gradient(270deg, #fe7f77 0%, #fe5f55 100%);
          box-shadow: 0px 15px 30px 0px rgba(20, 102, 204, 0.16);
        }
      }

      .mobile-button {
        display: none;
      }
    }
  }
}

@media (max-width: 768px) {
  .landingv2-nav .nav-flex .links li a{
  color: black;
  }
  }