.mini-footer {
  // background-color: #eaeafa;
  padding: 1rem 3%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;

  // & > * {
  //   padding-right: 0.5rem;
  //   border-right: 1px solid #2a2a2a;
  // }

  .social-logos {
    display: flex;
    align-items: center;
    gap: 1rem;

    svg {
      height: 1.5rem;
      aspect-ratio: 1/1;
    }
  }

  .product-of {
    display: flex;
    align-items: center;
    gap: 1rem;

    a {
      text-decoration: none;
      color: #2d4890;
    }

    img,
    svg {
      height: 2rem;
      aspect-ratio: 1/1;
    }
  }
}

@media screen and (max-width: 768px) {
  .mini-footer {
    justify-content: center;
  }
}
