.video-section {
    max-width: 70%;
    margin: 0 auto;
    // padding: 20px;
    display:flex;

    .video-container {
      position: relative;
      overflow: hidden;
      width: 100%;
  
      .video {
        position: relative;
        width: 100%;
  
        iframe {
          width: 100%;
          height: 650px;
          max-width: 1900px;
          max-height: 800px;
          border-radius: 8px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          transition: transform 0.3s ease-in-out;
  
          &:hover {
            transform: scale(1.02);
            box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
            border-radius: 8px;
          }
        }
      }
    }
  }
  
  @media screen and (max-width: 768px) {
    .video-section {
      padding: 20px;
      max-width: 100%;
  
      .video-container {
        .video {
        iframe {
          max-width: 100%;
          height: 350px;
          
        }
      }
      }
    }
  }
  