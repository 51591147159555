.maintenance-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.maintenance-text > h1 {
  font-size: 50px;
  font-weight: 100;
  text-align: center;
}

.maintenance-text {
  font-family: Open Sans;
  font-weight: 100;
  font-size: 20px;
  display: block;
  width: 700px;
  padding: 50px;
  margin: 0 auto;
}

.maintenance-paragraph {
  text-align: justify;
  text-justify: inter-word;
}
