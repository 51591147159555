.newsHub-section {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  align-items: center;
  padding: 0% 10%;
  .links{
    text-decoration: none;
    color: black;
  }
  .links:hover{
    transform:  translateX(10px) scale(1.05);
  } 
  
  .selection-section {
    .selection-title h1 {
      // font-size: 1.5rem;
      font-weight: 700;
      // color: #fff;
      color: #2d4890;
    }
    .selection-content {
      // color: #fff;
      color: black;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      margin: 4rem 0;
      .selection-content-item {
        display: flex;
        gap: 2rem;
        align-items: center;

        .icon {
          background: #fff;
          border: 1px solid #3A3A3C;
          border-radius: 20px;
          backdrop-filter: blur(10px);
          padding: 1.5rem;
        }
      }
    }
  }
  .image-section {
    display: flex;
    align-items: center;
    gap: 2rem;
    .selection-image {
      display: flex;
      flex-direction: column;
      z-index: 1;
      gap: 1rem;
    }
    .selection-bg-img {
      img {
        position: absolute;
        right: 0;
        width: auto;
        height: auto;
      }
    }
  }
}

@media(max-width:768px){
  .newsHub-section {
    flex-direction: column;
    gap: 0rem;
    padding: 0% 5%;
    margin:2rem 0;
    .selection-section {
      .selection-title h1 {
        font-size: 1.75rem;
        text-align: center;
      }
      .selection-content {
        margin: 2rem 0;
        .selection-content-item {
          gap: 1rem;
          .icon {
            padding: 1rem;
          }
        }
      }
    }
    .image-section {
      .selection-image {
        gap: 1rem;
        max-height: 18rem;
        img {
          max-width: 100%;
          height: auto;
        }
      }
      .selection-bg-img {
        img {
          position: absolute;
          right: 0;
          width: 40%;
          height: auto;
        }
      }
    }
  }
}