.aggregator-news-tab {

    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    padding: 20px;
    background: white;
    border-radius: 7px;
    border: 1px solid black;

    // min-height: 80svh;
    // min-height: 80vh;
    min-height: 1100px; //300(i.e jus the graph) * 3 + extra height + margin 

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        margin-top: 10px;

        h6 {
            font-weight: bold;
            color: black;
            font-size: 14px;
        }

        span {
            font-size: 15px;
            font-weight: 500;
            color: #575c5c;
        }


        .Search-Sources {
            font-size: 13px;
            padding-right: 5px;
            color: black;
        }
    }


    .scrollable-container {
        font-size: inherit;
        font-family: inherit;
        overflow-y: scroll;
        scrollbar-color: rgb(57, 224, 164) transparent; //not used
        scrollbar-width: thin; //not used

        // min-height: 75svh;
        // min-height: 75vh;

        // max-height: 70svh;
        // max-height: 70vh;

        max-height: 980px; //min 1130 -130


   


        background-color: rgb(253, 253, 253);
        padding: 5px;

        &::-webkit-scrollbar {
            width: 10px;
        }

        &::-webkit-scrollbar-track {
            background: transparent; //not used
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgb(57, 224, 164);
            border-radius: 20px;
            border: 0px solid transparent; //not used
        }



        .small-cards {
            background-color: rgb(248, 248, 248);
            border-radius: 10px;
            margin-top: 10px;
            padding: 15px;
            font-weight: 500;
            font-size: 15px;

            .statusDots {
                height: 17px;
                width: 17px;
                border-radius: 50px;
            }

            .time {
                color: #9fa6a7;
            }

            .mint-text {
                line-height: 25px;
                overflow-wrap: break-word; //break along word
                color:black;

                span {
                    font-weight: 900;
                }
            }
        }

        .small-cards:hover, .small-cards:focus{
            background-color: rgb(209, 229, 247);
            cursor: pointer;
        }
    }


}


@media only screen and (max-width: 1400px) {
    .aggregator-news-tab {

        min-height: 800px; //300(i.e jus the graph) * 3 + extra height + margin - 100 * 3

        .scrollable-container {
            max-height: 680px; //min 730 -130 - 100 * 3
        }


    }
}


@media only screen and (max-width: 991px) {
    .aggregator-news-tab {

        min-height: 400px; //300(i.e jus the graph) * 3 + extra height + margin - 100 * 3

        .scrollable-container {
            max-height: 280px; //min 730 -130 - 100 * 3
        }


    }
}