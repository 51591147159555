.subscribe-price-listing {
    display: flex;
    justify-content: space-evenly;
    // align-items: center; //removed cause reduces height
    margin-top: 20px;
    padding: 10px;


    .pricing-card {

        border-radius: 12px;
        background: #fff;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        padding: 20px;
        min-width: 280px;
        max-width: 280px;
        ;
        margin: 10px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;



        .subscribe-type {
            margin-top: 15px;

            h3 {
                font-size: 17px;
                text-transform: uppercase;

            }

        }

        .useful-for {
            font-size: 14px;
        }

        .pricing-box {

            margin-top: 20px;

            width: 100px;
            background: rgb(211, 52, 52);
            color: #fff;
            text-align: center;
            border-radius: 50px;
            padding-top: 13px;
            padding-bottom: 13px;

            .price {
                font-size: 20px;
                font-weight: 600;
            }

            .month {
                font-size: 14px;
                font-weight: 400;
                color: #e6e0e0;

            }
        }

        .available-features {
            margin-top: 20px;
            margin-bottom: 10px;
            width: 100%;


            .feauture {

                display: flex;

                font-size: 14px;

                p {
                    margin-left: 5px;
                }

            }
        }


        .buy-button {
            margin-top: auto; //all have same height
            margin-bottom: 20px;

            button {
                outline: 0;
                border: 0;
                padding: 10px 16px;
                background: rgb(211, 52, 52);
                color: #fff;
                border-radius: 5px;
                text-transform: uppercase;
                font-size: 14px;
            }
        }
    }


}


@media only screen and (max-width: 1127px) {


    .subscribe-price-listing {
        display: flex;
        justify-content: flex-start; //needed reset 'justify-content' else hides first item in scroll for smaller width
        overflow-x: scroll;

    }
}