.new-classes-grid-container {
    display: flex;
    flex-wrap: wrap;
    //justify-content: center;
    align-items: center;

    .flex-item {
        margin: 5px;

        .highlight-text {
            font-size: 15px;
            font-weight: 500;
            font-family: "Open Sans";
            line-height: 25px;


            .unhighlight {
                color: black;
            }

        }
    }
}


.new-classes-no-news {
    font-size: 14px;
    font-weight: 500;
    font-family: "Open Sans";
    text-align: center;
}



@media only screen and (max-width: 991px) {
    .new-classes-grid-container {

        .grid-item {

            .highlight-text {
                font-size: 13px;
            }
        }
    }


    .new-classes-no-news {
        font-size: 13px;
    }

}