@font-face {
    font-family: OpenSans;
    src: url(../../assets/fonts/OpenSans-Regular.ttf);
}

@font-face {
    font-family: OpenSansSemiBold;
    src: url(../../assets/fonts/OpenSans-Semibold.ttf);
}



.custom-company-doc {

    h6 {
        font-family: OpenSansSemiBold;
        font-family: "Open Sans";
        font-size: 14px;
    }


    .ant-collapse-header-text {
        font-family: OpenSans !important;
        font-family: "Open Sans";
        font-size: 14px;
    }


    .graph-bullet-points {
        display: flex;

        .bullet-points ul {
            list-style-position: inherit;

            li {
                font-size: 14px;
                font-family: OpenSans;
                font-family: "Open Sans";
            }
        }
    }

    @media only screen and (max-width: 768px) {

        .graph-bullet-points {
            display: flex;
            flex-direction: column;


            .bullet-points {
                margin-top: 25px;

            }
        }
    }



    @media only screen and (max-width: 991px) {

        h6 {
            font-size: 13px;
        }

    

        .ant-collapse-header-text {
            font-size: 13px;
        }

        .doughnut-header h6 {
            margin-top: 15px;
            margin-bottom: 5px;
        }


        .graph-bullet-points .bullet-points ul {
            list-style-position: inside;

            li {
                font-size: 13px;
                font-family: OpenSans;
                font-family: "Open Sans";
            }
        }



    }
}