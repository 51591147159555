.News-Sentiment-Stacked-Bar-Line {

  padding: 10px;
  padding-top:0px;


  .white-box {
    padding: 10px;
    border-radius: 6px;
    background: #fff;
    height: 300px;

    h6 {
      text-align: center;
      font-weight: 500;
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
    }
  }



}



@media only screen and (max-width: 991px) {

  .News-Sentiment-Stacked-Bar-Line .white-box h6 {
    font-size: 13px;
  }

}