.news-article {
  .inner-content {
    background-color: #fbfbfb;
    border-radius: 6px;

    // max-height: 75vh;
    // min-height: 75vh;
    h6 {
      font-family: "Open Sans";
      // font-weight: 500;
      font-size: 14px;
      font-weight: bold;
      color: black;

      a {
        font-weight: bold;
        cursor: pointer;
      }
    }

    span {
      font-size: 15px;
      font-weight: 500;
      color: #575c5c;
    }

    .header {
      display: flex;
      justify-content: space-around;
    }
  }

  .max-height-80 {
    min-height: 80%;
  }

  .background-white {
    background-color: white;
    border-radius: 5px;
    cursor: pointer;

    p.no-news {
      font-size: 15px;
    }

    .statusDots {
      height: 17px;
      width: 17px;
      border-radius: 50px;
    }

    .time {
      color: #9fa6a7;
      font-size: 15px;
      font-weight: 500;
      font-family: "Open Sans";
    }

    // ul {
    //   list-style-position: inherit; //used for news classes 
    // }

    // .highlight-text{  //used for new classes
    //   font-size: 15px;
    //   font-weight: 500;
    //   font-family: "Open Sans";
    //   line-height: 25px;
    
  

    //   .unhighlight{
    //     color:black;
    //   }

    // }

    .mint-text {
      font-size: 15px;
      font-weight: 500;
      font-family: "Open Sans";
      line-height: 25px;

      ul {
        list-style-position: inherit;
      }
    }


  }

  .insider-child {
    button {
      background: #cdd9f9;
      color: black;
      font-weight: 500;
      border-radius: 7px;
    }

    position: relative;
    overflow: hidden;

    scrollbar-color: rgb(57, 224, 164) transparent;
    scrollbar-width: thin;
    // -ms-overflow-style: none; /* IE and Edge */
    // scrollbar-width: none; /* Firefox */
    // &::-webkit-scrollbar {
    //   display: none;
    // }

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgb(57, 224, 164);
      border-radius: 20px;
      border: 0px solid transparent;
    }

    .scroll-button {
      background: #3956a3;
      border-radius: 50px;
      height: 43px;
      max-width: 43px;
      min-width: 43px;
      position: absolute;
      bottom: 15px;
      right: 15px;
    }
  }
}



@media only screen and (max-width: 991px) {
  .news-article .inner-content h6 {
    font-size: 13px;

    a {
      font-size: 13px;
    }
    
  }


  .news-article .background-white p.no-news {
    font-size: 13px;
  }


  .news-article {
    .inner-content {
      h6 {
        font-size: 13px;
      }
      span {
        font-size: 13px;
      }
    }
  
    .background-white {
      p.no-news {
        font-size: 13px;
      }

      .time {
        font-size: 13px;
   
      }

      .mint-text {
        font-size: 13px;
      }

      // .highlight-text{ //used for news classes
      //   font-size: 13px;
      // }
    }
}

}


@media only screen and (max-width: 768px) {
  .news-article .background-white {

    // ul {
    //   list-style-position: inside; //used for news classes
    // }
    .mint-text ul {
      list-style-position: inside;
    }
 

  }
}