.market-partner-section {
  .partner-section{
    padding: 5% 18%;
    .main-title{
      margin: 2rem;
      font-size: 2.5rem;
      font-weight: 600;
      text-align: center;
    }
    .partner-container{
      display: flex;
      flex-direction: column;
      gap: 2rem;
      align-items: center;
      justify-content: center;
      border: 1px solid #5C7CFA;
      padding:3rem;
      border-radius: 15px;
      .title-logo{
        display: flex;
        gap:6px;
        align-items: center;
        .title{
          font-size: 2.5rem;
          font-weight: 700;
        }
      }
      .quotes-content{
        font-size: 1.2rem;
        text-align: center;
        line-height: 2.5;
        a {
          color: #000;
        }
      }
    }
  }
  .market-container {
    display: flex;
    justify-content: space-between;
    gap:2rem;
    align-items: center;
    margin: 2% 10%;
    // background: rgb(43, 43, 43, 0.7);
    background-color: #fff;
    border-radius: 20px;
    padding: 2%;
    box-shadow:0px 15px 30px 0px rgba(20, 102, 204, 0.16);

    .market-text-btn {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      text-align: center;
      // color: #fff;
      color: black;
      .market-title {
        font-size: 1.5rem;
        font-weight: 700;
        color:#2d4890;
      }
      .market-content {
        font-size: 1rem;
      }

      .button {
        width: 100%;
        display: flex;
        justify-content: center;

        button {
          color: var(--neutrals-neutrals-1, var(--Fabric-Color-Pallete, #fff));
          padding: 1rem;
          border: #fe5f55;
          border-radius: 1.5rem;
          background: linear-gradient(270deg, #fe7f77 0%, #fe5f55 100%);
          box-shadow: 0px 15px 30px 0px rgba(20, 102, 204, 0.16);
          font-family: DM Sans;
          font-size: 1rem;
          font-weight: 700;
          line-height: 1.25rem; /* 90.909% */
          text-transform: uppercase;
          margin: 2rem auto;
        }
      }
    }

    .market-img{
      box-shadow: 0px 15px 30px 0px rgba(20, 102, 204, 0.16);
    }
  }
  .disclaimer-note{
    font-size: 1.5rem;
    color: #000;
    margin: 2rem 6rem;
    padding: 2rem;
    border: 1px solid #5C7CFA;
    h1{
      text-align: center;
      font-size: 2rem;
      font-weight: 700;
      color: #2d4890;
    }
    p{
      text-align: left;
      padding: 1rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .market-partner-section {
    .market-container {
      flex-direction: column-reverse;
      gap: 4rem;
      margin: 5% 10%;
      padding:5%;

      .market-text-btn {
        .market-title {
          font-size: 1.5rem;
          color:#2d4980;
        }
        .market-content {
          font-size: 1rem;
        }
      }
      .market-img{
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
  .partner-container  .title{
    font-size: 2.2rem;
    font-weight: 600;
  }
  .quotes-content{
    font-size: 1rem!important;
    text-align: center!important;
    line-height: 2!important;
  }
}