.backtesting{
  .table {
    width: 100%; // Full width table
    border-collapse: collapse; // Remove space between cells
    // border: 2px solid #062880; // Blue border around table
    border-radius: 15px; // Rounded corners for table
  
    &.table-striped {
      th{
        padding: 15px 35px; // Padding inside cells
        text-align: center; // Align text to the left
        border-bottom: 1px solid #ddd; // Bottom border for separation
  
        &:first-child {
          border-top-left-radius: 15px; // Rounded corners for first cell in header
        }
  
        &:last-child {
          border-top-right-radius: 15px; // Rounded corners for last cell in header
        }
      }
  
      th {
        background-color: #062880;
        color: white; // White text for contrast
        font-weight: bold; // Bold font for headers
      }
  
      td {
        vertical-align: top; // Align content to top of cell
        padding: 15px 35px; // Padding inside cells
        text-align: center; // Align text to the left
        border-bottom: 1px solid #ddd; // Bottom border for separation
      }
    }
  }
}