:root {
    --bot-main-theme: rgb(0, 102, 255);
    --user-main-theme: #fff;
    --chat-body: #e6eff2;
    --main-body: #fff;
    --header-input-border: rgb(226, 226, 226);
}

.popover {
    position: fixed;
    bottom: -200%; //cause of orientatin landcape
    right: 16px;
    transition: all 0.3s ease-out;

    border: none;
    border-radius: 20px;
    min-width: 350px; //width of chatbot
    //box-shadow: 0px 1px 12px 1px rgb(182, 180, 180);
    z-index: 11;
    font-family: "Open Sans";

}

.popover-trigger-open {
    border-radius: 50%;
    width: 70px;
    height: 70px;
    background-color: var(--bot-main-theme);
    color: #fff;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    .show-button {
        font-size: 34px;
    }
}

.small-avatar {
    background: var(--bot-main-theme);
    color: white;
    width: 16px;
    font-size: 10px;
    height: 16px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popover.open {
    //on visible on screen css
    bottom: 20px;
    border: none;
    border-radius: 20px;
    min-width: 350px; //width of chatbot
    box-shadow: 0px 1px 12px 1px rgb(182, 180, 180);
    z-index: 11;
    font-family: "Open Sans";
}

.chat-header {

    background-color: var(--main-body);
    color: black;
    padding: 20px 10px;
    border-top-left-radius: 20px; //needed cause div overlap
    border-top-right-radius: 20px;

    // border: 1px solid black;

    position: relative;
    z-index: 11;
    box-shadow: 0 5px 6px -6px rgb(167, 167, 167);
    border: .5px solid var(--header-input-border);


    .combine-design {

        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex: 0.5; //helps take only half space

        .avatar {
            background: var(--bot-main-theme);
            color: white;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 30px;
        }

        .header-design {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: flex-start;
            padding-left: 10px;

            h3 {

                font-size: 20px;
                margin: 0;
                font-weight: 600;
            }

            .online {
                opacity: 0.6;
                color: gray;
                margin: 0;
                font-size: 14px;
            }

        }


    }


    .popover-trigger-close {
        padding: 10px;
        padding-top: 0;
        font-size: 20px;
        cursor: pointer;
        color: rgb(94, 87, 87);
        background-color: var(--main-body);
    }

}

.chat-body {
    //   position: relative; //suggestions pills absolute
    background-color: var(--chat-body);
    //only chat body height
    min-height: 50vh;
    min-height: 50svh;

    max-height: 50vh;
    max-height: 50svh;


    overflow-y: scroll;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;

    .chatBotInfo {
        // display: inline-block;
        margin-bottom: 3px;
        display: flex;

        p {
            font-size: 13px;
            padding-left: 3px;
            font-weight: 400;
        }
    }

    .userInfo {
        //display: inline-block;
        margin-bottom: 3px;
        display: flex;
        justify-content: flex-end;

        p {
            font-size: 13px;
            padding-right: 3px;
            font-weight: 400;
        }
    }

    .chat-message {
        display: inline-block;
        margin-bottom: 8px;
        padding: 10px 20px;
        border-radius: 15px;
        font-size: 14px;
        line-height: 1.5;
        max-width: 85%;


        .message-with-user-info {
            display: flex;
            justify-content: space-evenly;
        }
    }

    .chatbot {
        background-color: var(--user-main-theme);
        align-self: flex-start;
        font-weight: 500;
    }

    .user {
        background-color: var(--bot-main-theme);
        color: white;
        align-self: flex-end;
        font-weight: 400;
    }

}

.chat-input {
    border: .5px solid var(--header-input-border);

    padding: 12px 10px;
    background-color: var(--main-body);
    display: flex;

    input[type="text"] {
        flex: 1;
        font-size: 14px;
        border: none;
        outline: none;
        background-color: #fff;
        color: black;
        // color: #333;
    }

    input[type="text"]::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        opacity: 0.5;
        color: gray;
    }

    input[type="text"]::-moz-placeholder {
        /* Firefox 19+ */
        opacity: 0.5;
        color: gray;
    }

    input[type="text"]:-ms-input-placeholder {
        /* IE 10+ */
        opacity: 0.5;
        color: gray;
    }

    input[type="text"]:-moz-placeholder {
        /* Firefox 18- */
        opacity: 0.5;
        color: gray;
    }

    button {
        background-color: #fff;
        color: #007bff;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s ease;

        .send-Msg-Button {
            font-size: 20px;
        }
    }


    button:disabled {
        opacity: 0.5;
        color: gray;
    }
}

.chat-footer {
    border-bottom-left-radius: 20px; //needed cause div overlap
    border-bottom-right-radius: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0;

    p {

        span {
            opacity: 0.6;
            color: gray;
            font-weight: 400;
        }

        font-size: 13px;
        font-weight: 500;
        color: var(--bot-main-theme);
        // margin:0;
    }
}



.all-pills {
    // margin-top: 5px;               //for suggestion inside chat
    // margin-bottom: 5px;

    // margin: 5px 0px;                   //for two buttons
    margin: 5px 5px;
    background-color: #fff;
    cursor: pointer;

    // display: flex;                               //for two buttons
    // //justify-content: center;
    // justify-content: space-evenly;
    // align-items: center;

    // background-color: var(--chat-body);           //for suggestion inside chat
    //    position:absolute;
    //    bottom:10px;

}

.togther-with-pill {
    display: flex; //icon and text together
    // justify-content: space-between ;  
    justify-content: center;
    align-items: center;



    //border-radius: 15px;                             //for suggestion inside chat
    //border: 1px solid rgb(0, 102, 255);
    //background-color: #fff;
    //color: rgb(0, 102, 255);
    // margin: 2px;

    color: black;
    background-color: #ecedf5;
    // background-color: #fff;               //for suggestion inside chat
    border-radius: 3px;

    font-size: 14px;
    font-weight: 600;

    padding: 6px 10px;
}

.Knowledge-icon {
    font-size: 16px;
    color: orange;
    padding-right: 2px;
}

.start-chat-icon {
    font-size: 16px;
    color: greenyellow;
    padding-right: 2px;

}

.togther-with-pill:hover,
.togther-with-pill:focus {
    color: rgb(16, 102, 231);
    background-color: #ecedf5;
}


@keyframes slide-up {
    from {
        bottom: -200%;
    }

    to {
        bottom: 20px;
    }
}

@media only screen and (max-width: 576px) {

    .popover {
        min-width: 90%;
        right: 2px;
    }

    .popover.open {
        min-width: 90%;
        right: 2px;
    }
}

@media only screen and (max-width: 991px) {

    .chat-header {

        .combine-design {

            .header-design {

                h3 {

                    font-size: 18px;
                }

                .online {
                    font-size: 13px;
                }
            }

        }



    }

    .chat-body {


        min-height: 50vh;
        min-height: 50svh;


        max-height: 50vh;
        max-height: 50svh;

        .chatBotInfo {
            p {
                font-size: 12px;
            }
        }

        .userInfo {

            p {
                font-size: 12px;
            }
        }

        .chat-message {
            font-size: 13px;
        }


        .togther-with-pill {

            font-size: 13px;

        }

    }

    .chat-input {

        input[type="text"] {
            font-size: 13px;
        }

    }

    .chat-footer {
        p {
            font-size: 12px;
        }
    }


    .togther-with-pill {
        font-size: 13px;
    }

}

@media only screen and (min-width: 600px) and (max-width:800px) and (orientation : landscape) {

    .chat-body {
        min-height: 20vh;
        min-height: 20svh;

        max-height: 20vh;
        max-height: 20svh;

    }
}