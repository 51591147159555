.news-content {
  .inner-content {
    background-color: #fbfbfb;
    border-radius: 6px;

    // max-height: 75vh;
    // min-height: 75vh;
    h6 {
      font-family: "Open Sans";
      // font-weight: 500;
      font-weight: bold;
      color: black;
      font-size: 14px;
    }

    p {
      font-size: 15px;
    }

    span {
      font-size: 15px;
      font-weight: 500;
      color: #575c5c;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2px;

      .Search-Sources {
        font-size: 13px;
        padding-right: 5px;
        color: black;
        font-family: "Open Sans";
      }
    }
  }

  .background-white {
    background-color: white;
    border-radius: 5px;
    margin-right: 6px;
    cursor: pointer;

    .statusDots {
      height: 17px;
      width: 17px;
      border-radius: 50px;
    }

    .time {
      color: #9fa6a7;
      font-size: 15px;
      font-weight: 500;
      font-family: "Open Sans";
    }

    .mint-text {
        font-size: 15px;
      font-weight: 500;
      font-family: "Open Sans";
      line-height: 25px;
      overflow-wrap: break-word;

      span {
        font-weight: 900;
      }
    }
  }

  .insider-child {
    position: relative;
    overflow-y: scroll;
    max-height: 59vh;
    max-height: 59vh;

    // -ms-overflow-style: none; /* IE and Edge */
    // scrollbar-width: none; /* Firefox */
    // &::-webkit-scrollbar {
    //   display: none;
    // }

    scrollbar-color: rgb(57, 224, 164) transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgb(57, 224, 164);
      border-radius: 20px;
      border: 0px solid transparent;
    }

    .scroll-button {
      background: #3956a3;
      border-radius: 50px;
      height: 43px;
      max-width: 43px;
      min-width: 43px;
      position: absolute;
      bottom: 15px;
      right: 15px;
    }
  }
}



@media only screen and (max-width: 991px) {
  .news-content .inner-content h6 {
    font-size: 13px;
  }

  .news-content .inner-content p {
    font-size: 13px;
  }

  .news-content {
    .background-white {

      .time {
        font-size: 13px;
      }

      .mint-text {
        font-size: 13px;
      }

    }
  }


}