.floatdown-button {
  z-index: 2;
  border: none;
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: transparent;
  cursor: pointer;
  
  svg {
    width: 2rem;
    height: 2rem;
  }
}
