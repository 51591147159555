.news-aggregator {

    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    border-radius: 5px;
    cursor: pointer;

    .search-container {

        display: flex;
        justify-content: space-evenly;
        align-items: flex-start;
        padding: 20px;
        // width: 75%;
        width: 100%;
        border-radius: 10px;
        background: #fff;
        min-height: 140px;

        .aggregator-title {
            font-family: inherit;
            margin-top: 22px;

            h2 {
                margin: 0;
                font-weight: 600;
                font-size: 22px;
            }
        }


        .search-item-conatiner {
            font-size: inherit;

            label {
                font-size: inherit;
                font-family: inherit;
                font-weight: 500;
                margin-bottom: 1px;

                span {
                    color: rgb(221, 15, 15);
                }

            }


            .search-item {
                min-width: 100px; //override for searchbar
            }

            //margin-bottom: 20px;
            .alert-box {
                color: #721c24;
                background-color: #f8d7da;
                border: 1px solid #f5c6cb;
                padding: 5px;
                border-radius: 3px;
                text-align: center;

                p {
                    font-size: inherit;
                    font-family: inherit;
                    font-weight: 400;
                }

            }

        }


        .search-button-style {
            font-size: inherit;
            margin-top: 22px;
           

            .custom-submit {
                background-color: #062880;
                color: #fff;
                border: none;
                cursor: pointer;
                padding: 5px 15px;
                border-radius: 3px;
                transition: background-color 0.3s ease;
                font-family: "Open Sans";
                font-size: inherit;
            }

            .custom-submit:focus {
                background-color: #07329e;
                color: rgb(248, 232, 232);
            }

        }


    }


}


@media only screen and (max-width: 1134px) {


    .news-aggregator {
        font-size: 13px;

        .search-container {
            //  width: 95%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-items: center;

            .aggregator-title {
                margin-top: 10px;
                h2{
                    font-size: 16px;
                }
            }

            .search-item-conatiner {
                margin-top: 10px;
            }

            .search-button-style {
                margin-top: 10px;
            }

        }

    }

}