.landingv2-feature-section {
  // padding: 0 10%;
  margin-bottom: 4%;

  .heading{
    display: flex;
    align-items:last baseline;
    flex-direction: column-reverse;
  }

  .title {
    color: var(--fabric-blue-fabric-blue-900, #131e3c);
    // color:white;
    font-family: DM Sans;
    font-size: 1.75rem;
    text-align: center;
    margin-top: 1.5rem;
    font-weight: 800;
  }

  .subtitle {
    margin-top: 2rem;
    // color: #ffffff;
    text-align: center;
    font-family: DM Sans;
    font-size: 1rem;
    font-weight: 400;
    padding:0 10%;

    .highlight {
      color: #fe5f55;
      font-weight: 700;
    }
  }

  .cube-img{
    img{
      max-width:40%;
      height:auto;
    }
  }

  .feature-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 2rem;
    margin-top: 2rem;
    padding: 0 10%;
  }
}

@media (min-width: 768px) {
  .landingv2-feature-section {
    
    .heading{
      flex-direction: row;
    }

    .title {
      // color:white;
      color: var(--fabric-blue-fabric-blue-900, #131e3c);
      font-family: DM Sans;
      font-size: 3.5rem;
      text-align: center;
      margin-top: 3.5rem;
    }

    .subtitle {
      margin-top: 1.5rem;
      // color: #fff;
      text-align: center;
      font-family: DM Sans;
      font-size: 1.3rem;
      font-weight: 400;
      line-height: 2.5rem; /* 190.476% */

      .highlight {
        color: #fe5f55;
        font-weight: 700;
      }
    }
    .cube-img{
      img{
        position: absolute;
        max-width: 30%;
        height: auto;
      }
    }

    .feature-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 3rem;
      margin-top: 2rem;
      padding: 0 27%;
    }
  }
}
