.news-topics {
  .background-grey {
    background-color: #fbfbfb;
    border-radius: 6px;
    // min-height: 75vh;
    // max-height: 75vh;
    position: relative;

    h6 {
      font-family: "Open Sans";
      font-weight: bold;
      margin-bottom: 5px;
      font-size: 14px;
    }

    p{
      font-family: "Open Sans";
      margin-bottom: 10px !important;
      font-size: 13px;
    }
  }

  .light-green-btn {
    background: #84fed2;
    color: black;
    font-weight: 500;
    border-radius: 7px;
    padding: 0 8px;
    font-size: 12px;
    overflow-wrap: anywhere;
  }
}


@media only screen and (max-width: 991px) {
  .news-topics .background-grey h6 {
    font-size: 13px;
  }

  .news-topics .background-grey p {
    font-size: 13px;
  }

}