.technical-indicators-container {
  background-color: #ffffff;
  display: grid;
  grid-template-columns: repeat(3, 1fr); // 3 columns
  grid-gap: 10px; // Adjust the gap between cards
  padding: 15px; // Adjust the padding around the container as needed
  overflow-x: hidden; // Prevent horizontal scrolling at the container level
}

@media (max-width: 1024px) {
  .technical-indicators-container {
    grid-template-columns: repeat(2, 1fr); // 2 columns for tablet or medium-sized screens
  }
}

@media (max-width: 768px) {
  .technical-indicators-container {
    grid-template-columns: 1fr; // 1 column for mobile view
  }
}

.plot-container-1 {
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 100%;
  height: 100%; // Make sure the plot container takes full height
  border-radius: 10px; // Adjust for consistent design
  overflow: hidden; // Prevent overflow
  padding: 10px; // Add padding inside the plot containers
}

.plot-container-1 > * {
  width: 100%; // Ensure the plot takes up 100% of the container's width
}

.plot-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.info-icon {
  font-size: 18px;
  cursor: pointer;
  margin-left: 10px;
}

.plot-header h4 {
  margin: 0;
}

@media (max-width: 600px) {
  .plot-container-1 {
    overflow-x: auto;
    height: auto; // Adjust height for mobile
  }

  .plot-container-1 > * {
    min-width: 100%; // Ensure plots take full width on smaller screens
  }

  .plot-header h4 {
    font-size: 1.5rem;
  }
}
