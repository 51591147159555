.confidence-section{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5% 10%;
  // background-image: linear-gradient(
  //   135deg,
  //   rgba(18, 29, 58, 0.15),
  //   rgba(254, 94, 82, 0.15) 100%
  // );
  .links{
    text-decoration: none;
    color: black;
  }
  .links:hover{
    transform:  translateX(10px) scale(1.05);
  } 
  .selection-section {
    .selection-title h1 {
      font-size: 1.75rem;
      font-weight: 700;
      // color: #fff;
      color: #2d4890;
      text-align: center;
    }
    .selection-content {
      // color: #fff;
      color: black;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      margin: 3rem 0;
      .selection-content-item {
        display: flex;
        gap: 2rem;
        align-items: center;

        .icon {
          background: #fff;
          border-radius: 20px;
          backdrop-filter: blur(10px);
          padding: 1.5rem;
          border: 1px solid #3A3A3C;
        }
      }
    }
  }
  .image-section {
    display: flex;
    // flex-direction: column;
    align-items: center;
    gap: 1rem;

    .selection-image {
      display: flex;
      flex-direction: column;
      z-index: 1;
      gap: 1rem;
      max-width: 100%;

      img {
        max-width: 100%;
        height: auto;
      }

      .img2-3 {
        display: flex;
        flex-direction: row;
        gap: 1rem;

        .img2,
        .img3 {
          flex: 1;
        }
      }
    }
  .selection-bg-img {
    img {
      position: absolute;
      right: 0;
      width: 60%;
      height: 70%;
    }
  }
}
}

@media (min-width:768px){
  .confidence-section {
    display: flex;
    justify-content: space-between;
    gap:2rem;
    align-items: center;
    padding: 3% 10%;
    flex-direction: row;
    // background-image: linear-gradient(
    //   135deg,
    //   rgba(18, 29, 58, 0.15),
    //   rgba(254, 94, 82, 0.15) 100%
    // );
    .selection-section {
      .selection-title h1 {
        font-size: 2rem;
        font-weight: 700;
        // color: #fff;
        color: #2d4890;
      }
      .selection-content {
        // color: #fff;
        color: black;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin: 4rem 0;
        .selection-content-item {
          display: flex;
          gap: 2rem;
          align-items: center;
  
          .icon {
            background: #fff;
            border: 1px solid #3A3A3C;
            border-radius: 20px;
            backdrop-filter: blur(10px);
            padding: 1.5rem;
          }
        }
      }
    }
    .image-section{
      display: flex;
      align-items: center;
      gap:2rem;
    .selection-image{
      display: flex;
      flex-direction: column;
      z-index:1;
      gap:1rem;
      .img2-3{
          gap:1rem;
          display: flex;
          flex-direction: row;
      }
    }
    .selection-bg-img {
      img {
        position: absolute;
        right: 0;
        width: auto;
        height: auto;
      }
    }
  }
  }  
}
