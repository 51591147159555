.dots-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 4px;
  height: 4px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: #333;
  animation: dot-animation 1.5s ease-in-out infinite;
}

.dot:nth-child(2) {
  animation-delay: 0.5s;
}

.dot:nth-child(3) {
  animation-delay: 1s;
}

@keyframes dot-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
