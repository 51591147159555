.search-ticker{
    width: 20%;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    margin: 1rem 0;
    .stock-options {
      position: absolute;
      z-index: 9;
      width: inherit;
      margin-top: 0.25rem;
      background-color: white;
      border: 1px solid #d1d5db;
      border-radius: 0.375rem;
      box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
      max-height: 12rem;
      overflow: auto;
      scrollbar-width: thin;
    }
    
    .stock-options ul {
      padding: 0.25rem 0;
    }
    
    .stock-options li {
      padding: 0.5rem 1rem;
      font-size: 0.875rem;
      color: #374151;
      cursor: pointer;
    }
    
    .stock-options li:hover {
      background-color: #e5edff;
    }
   .stock-input{
    color: #2d4890;
    padding:0.5rem;
    width: 100%;
    height: 100%;
    border:none;
    border-bottom:1px solid #2d4890;
    background: linear-gradient(90deg, #e9eff7 0%, #f6e9eb 100%);
    border-radius: 10px;
   }
   .stock-input::placeholder {
    color: darkblue;
    // font-weight: bold;
    font-size: 1.2rem;
    font-weight: 500
  }

  }  
  

  @media screen and (max-width: 768px) {
    .search-ticker {
      width: 100%;
      z-index: 10;
      .stock-options {
        z-index: 10;
        max-height: 8rem;
        width: 93%;
      }
    }
  }