.AggTreeMapChart-col-margin {
    margin-bottom: 10px;

    .outer-wraper {
        padding: 10px;
        padding-bottom: 20px;
        border-radius: 6px;
        background: #fff;

        .graph-text-heading {
            text-align: center;
            font-weight: 500;
            font-family: "Open Sans";
            font-size: 14px;

        }

        .graph-container {
            min-height: 300px;
            max-height: 300px; //overwriiten inside canvas of graph
            width: 100%;
        }
    }

}


@media only screen and (max-width: 991px) {
    .AggTreeMapChart-col-margin {
        margin-bottom: 20px;

        .outer-wraper .graph-text-heading {
            font-size: 13px;
        }

    }
}


@media only screen and (max-width: 1400px) {
    .AggTreeMapChart-col-margin .outer-wraper .graph-container {
        min-height: 200px;
        max-height: 200px;
    }

}