.main-menu {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  .header {
    .backButton {
      border: 1px solid #062880;
      color: #062880;
      text-transform: capitalize;
      font-weight: 500;
      font-size: 15px;
      padding: 0.3rem 1rem;
      background: #fff;
      cursor: pointer;
      border-radius: 10px;
      position: fixed;
      top:15%;
      left:1%;
      z-index: 1000;
    }
  }
  .main-menu-header {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    // flex-direction: column;
    margin: 3rem 0;
    .stock-logo{
      width: 50px;
      height: 50px;
    }
  
    .bank-name {
      font-family: "Open Sans";
      font-weight: 700;
      color: #062880;
      text-align:center;
      margin-bottom: 0;
    }
  
    .bank-branch {
      font-family: "Open Sans";
      font-weight: 900;
    }
  
    .finance {
      font-family: "Open Sans";
      color: #062880;
      background-color:#a8afc1;
      padding: 2px 12px;
      border-radius: 8px;
      font-weight: 600;
      text-align: center;
    }
  }

  .main-menu-tabs {
    display: none;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;

    .tab-content {
      min-width: 150px;
      max-width: 225px;
    }
  }

  //.MuiButtonBase-root:hover {
  .Tab-custom-style:hover {
    border-radius: 15px !important;
    text-shadow: 1px 0 !important;
    box-shadow: 1px 1px 3px !important;
    background-color: whitesmoke !important;
  }

  .Mui-selected:hover,
  .Mui-selected {
    border-radius: 15px !important;
    text-shadow: 1px 0 !important;
    box-shadow: 1px 1px 3px !important;
    background-color: whitesmoke !important;
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  //.Mui-selected {
  //color: white !important;
  // background-color: #062880 !important;
  // border-radius: 11px;
  //}

  .css-19kzrtu {
    padding: 24px 0;
  }

  .css-8atqhb {
    position: relative;
  }
}
.menu-marquee-container {
  width: 100%; /* Set the width as desired, can be a fixed width or percentage */
  overflow: hidden; /* Ensures text is hidden outside the container */
  white-space: nowrap; /* Prevents text from wrapping */
  box-sizing: border-box;
  // border: 1px solid #000; /* Optional: for visual debugging */
}

.menu-marquee-text {
  display: inline-block;
  font-weight: bold;
  color: #959db5 !important;
  white-space: nowrap;
  animation: marquee 30s linear infinite;
  font-size: 20px;
}

@media screen and (min-width: 768px) {
  .menu-marquee-container {
   display: none;
  }

  .main-menu {
    margin-left: 3rem;
    margin-right: 3rem;
    // background: linear-gradient(90deg, #e9eff7 0%, #f6e9eb 100%);

    .main-menu-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .header {
        .backButton {
          border: 1px solid #062880;
          color: #062880;
          text-transform: capitalize;
          font-weight: 500;
          font-size: 15px;
          padding: 0.3rem 1rem;
          background: #fff;
          cursor: pointer;
          border-radius: 10px;
        }
      }      
    }
    .main-menu-header {
      min-width: 225px;
      flex-direction: column;
      gap: 0.5rem;
      margin:1rem 0;

      .stock-logo {
        width: 100px;
        height: 100px;
      }

      .bank-name {
        font-size: 1.5rem;
        margin-bottom: 0;
      }

      .bank-branch {
        font-size: 1.25rem;
      }
    }
    .main-menu-tabs {
      display: flex !important;
      .tab-content {
        min-width: 225px;
      }
    }
  }
}
