.news {
  margin-top: 0px;
  .mint-text {
    font-size: 15px;
    font-weight: 500;
    font-family: "Open Sans";
    line-height: 25px;
  }
  .bar-title {
    font-size: 15px;
    font-weight: 900;
    color: black;
    display: flex;
    font-family: "Open Sans";
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 25%;
    top: 8px;
    right: 0;
    white-space: nowrap;
    
    span {
      padding-right: 5px;
    }
  }

  @media (max-width: 1269px) {
    .bar-title {
      position: unset;
    }
  }

  .noUi-connect {
    background: rgb(6 40 128);
  }
}


//text and days number
@media only screen and (max-width: 1026px) {
  .news .mint-text {
    padding-left: 10px;
  }

  .news .bar-title span {
    padding-right: 15px;
  }

}


@media only screen and (max-width: 600px) {
  .news  .bar-title {
    width: 70%;
  }
}


@media only screen and (min-width: 601px) and (max-width: 991px) {
  .news  .bar-title {
    width: 50%;
  }
}