.horizontal-card {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 0%), 0px 1px 1px 0px rgb(0 0 0 / 0%),
    0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
  // display: flex;
  // align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 8px !important;
  //max-height: 5rem;

  .css-46bh2p-MuiCardContent-root:last-child {
   // padding-bottom: 16px;
  }

  .card-icons {
    width: 55px;
    height: 55px;
  }
  .activity-numbers {
    font-weight: 500;
    //font-size: 20px;
    font-size: 15px;
    color: #000000;
  }

  .activity-status {
    //font-size: 15px;
    font-size: 15px;
    font-weight: 400;
    color: #9fa6a7;
  }
}


@media only screen and (max-width: 991px) {


  .horizontal-card {

    .activity-numbers {
      font-size: 13px;
    }
  
    .activity-status {
      font-size: 13px;
    }
  }
  

}
