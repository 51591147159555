.stock-selection-top{
  .subNavbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 3%;
    padding-left: 1rem;
    background: linear-gradient(whitesmoke 0%, #eaeafa 100%);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    // z-index: 100;
    position: sticky;
    top: 0;
    .nav-items{
      display: flex;
      align-items: center;
      gap: 1rem;
      margin: 0;
      padding: 0;
      .nav-link a,
      .nav-link button{
        padding: 0.2rem 0.5rem;
        border-radius: 1rem;
        text-decoration: none;
        color: var(--brand-brand-fabric-blue, #2d4890);
        font-family: "Open Sans", sans-serif;
        font-size: 1rem;
        font-weight: 400;
        &:hover{
          background-color: #2d4890;
          color: #fff;
        }
        &.active{
          background-color: #2d4890;
          color:#fff
        }
      }
      .dropdown{
        // display: none;
        position: absolute;
        top: 102%;
        // left: 0;
        // background-color: white;
        background: linear-gradient(whitesmoke 0%, #eaeafa 100%);
        // border:#9faac8 1px solid;
        border-radius: 0 0 10px 10px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
        list-style: none;
        padding: 0;
        margin: 0;
        z-index: 1000;

        .dropdown-item {
          padding: 8px 16px;

          a {
            color: #2d4890;

            &:hover {
              background-color: #2d4890;
              color: #fff;
            }
          }
        }
      }

      &:hover .dropdown {
        display: block;
      }
    }
  }
}
@media (max-width: 600px) {
  .subNavbar .nav-items .ul {
    padding-left: 0rem;
  }
  .subNavbar .nav-link a{
    gap: 1rem !important;
    font-size: 0.75rem !important;
 }
 .subNavbar .nav-items{
  gap: 1rem !important;
 }     
}