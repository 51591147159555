.stock-select {
    height: 3.5rem ;
    position: relative;
    width: 40%;
    margin-top: 2rem;
    background: linear-gradient(77.46deg, #77A9F0 0%, #D76D77 49%, #FE5F55 98%);
    border-radius: 10px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    padding: 2px;
  }
  
  .stock-select-button {
    width: 100%;
    padding: 0.5rem 1rem;
    text-align: left;
    background-color:#fff;
    color:#2d4890;
    border-radius: 10px;
  };
  
  .stock-select-button:focus {
    outline: none;
    // ring: 2px solid #2d4890;
    // border-color: #2d4890;
  }
  
  .stock-select-text {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 1.2rem;
    font-weight: 500;
    text-align: left;
  }
  
  .stock-select-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding-right: 0.5rem;
    pointer-events: none;
  }
  
  .stock-select-icon svg {
    width: 1.25rem;
    height: 1.25rem;
    color: #9ca3af;
  }
  
  .stock-options {
    position: absolute;
    z-index: 9;
    width: 100%;
    margin-top: 0.25rem;
    background-color: white;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    max-height: 12rem;
    overflow: auto;
  }
  
  .stock-options ul {
    padding: 0.25rem 0;
  }
  
  .stock-options li {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    color: #374151;
    cursor: pointer;
  }
  
  .stock-options li:hover {
    background-color: #e5edff;
  }
 .stock-input-home{
  color: #2d4890;
  padding-left: 10px;
  width: 100%;
  height: 100%;
  border-radius: 10px;
 }
 .stock-input-home::placeholder {
  color: darkblue;
  // font-weight: bold;
  font-size: 1.2rem;
  font-weight: 500
}


  @media screen and (max-width: 768px) {
    .stock-select {
      width: 100%;
      z-index: 10;
    }
    .stock-options {
      z-index: 10;
      max-height: 8rem;
    }
  }