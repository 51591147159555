.landingv2-footer {
  min-height: 10vh;
  width: 100%;
  padding: 1rem 3%;
  background: linear-gradient(whitesmoke 0%, #eaeafa 100%);
  z-index: 10;
  position: relative;
  // background: rgb(43, 43, 43, 0.7);

  .pane-split {
    display: grid;
    grid-template-columns: 1fr;
    // padding-bottom: 3rem;
    border-bottom: 1px solid #000;

    .left-pane {
      .main-logo img {
        width: 100%;
        aspect-ratio: initial;
        object-fit: contain;
        padding: 1rem 25%;
      }
    }
    .links {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      list-style: none;
      padding: 0;
      margin: 1rem 0;
      justify-content: center;

      li a {
        text-decoration: none;
        color: #020014;
        // color: #fff;
      }
    }
    .social {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      margin: 2rem 0;

    }

    .right-pane {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;

      .title {
        color: var(--text-900, #101113);
        /* Heading/Subheading/Desktop/Medium */
        font-family: Poppins;
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.75rem; /* 140% */
      }

      .subtitle {
        color: var(--text-400, #5c5f66);
        /* Body/Paragraph M/Medium */
        font-family: Poppins;
        font-size: 0.75rem;
        font-weight: 500;
        margin-bottom: 2rem;
        padding: 0 2rem;
      }

      button {
        color: #fff;
        padding: 1rem 2rem;
        border: #fe5f55;
        border-radius: 1.5rem;
        background: linear-gradient(270deg, #fe7f77 0%, #fe5f55 100%);
        box-shadow: 0px 15px 30px 0px rgba(20, 102, 204, 0.16);
      }
    }
  }
  .bottom-footer{
    .bottom-footer-content{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      padding: 1rem;
      // color: #fff;
      color: #020014;
      font-size: 1rem;
    }
    .left-pane{
      display: flex;
      align-items: center;
      gap: 2rem;
    }
  }
}

@media (min-width: 768px) {
  .landingv2-footer {
    min-height: 10vh;
    width: 100%;
    padding: 1rem 3%;

    .pane-split {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      // padding-bottom: 3rem;
      border-bottom: 1px solid #000;

      .left-pane {
        .main-logo img {
          width: 40%;
          aspect-ratio: initial;
          object-fit: contain;
          padding: 1rem;
        }
      }
      .links {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        list-style: none;
        padding: 0;
        margin: 1rem 0;

        li a {
          text-decoration: none;
          color: #020014;
        }
      }
      .social {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;

      }

      .right-pane {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;

        .title {
          color: var(--text-900, #101113);
          /* Heading/Subheading/Desktop/Medium */
          font-family: Poppins;
          font-size: 1.25rem;
          font-weight: 500;
          line-height: 1.75rem; /* 140% */
        }

        .subtitle {
          color: var(--text-400, #5c5f66);
          /* Body/Paragraph M/Medium */
          font-family: Poppins;
          font-size: 1rem;
          font-weight: 500;
          line-height: 1.5rem; /* 150% */
          margin-bottom: 0;
          padding: 0;
        }

        button {
          color: #fff;
          padding: 0.5rem 1.5rem;
          border: #fe5f55;
          border-radius: 1.5rem;
          background: linear-gradient(270deg, #fe7f77 0%, #fe5f55 100%);
          box-shadow: 0px 15px 30px 0px rgba(20, 102, 204, 0.16);
        }
      }
    }
    .bottom-footer{
      .bottom-footer-content{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        padding: 1rem;
        // color: #fff;
        color: #020014;
        font-size: 1rem;
      }
      .left-pane{
        display: flex;
        align-items: center;
        gap: 2rem;
      }
    }
  }
}
