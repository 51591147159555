.dash-header-no-interact {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;


  .last-updated-item-box {
    display: flex;
    //justify-content: center;
    align-items: center;
    max-width: 300px; //needed or design comes on next line on mobiles

    // margin-right: 5px; //spacing
    // margin-bottom: 5px; //spacing

    color: white;
    font-size: 13px;
    text-align: center;
    background-color: white;
    padding: 0.3rem 0.5rem;
    border-radius: 10px;

    span.news-dated {
      text-transform: uppercase;
      display: inline-block;
    }
  }
}

.dash-header-flex-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;

  .flex-item-one {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    .flex-item-one-inner{
      display: flex;
      gap: 0.5rem;
      padding: 1rem 0;
    }

    .nifty50-toggle {
      button {
        font-size: 0.8rem;
        font-weight: 600;
        padding: 1rem;
        border-radius: 10px;
        background-color:mediumpurple;
        color: white;
        border: none;
        outline: none;
      }

      button.active {
        background-color: #4b0082;
      }
    }

    .searchbar-item {
      margin-bottom: 5px; //spacing
    }
  }

  .flex-item-two {
    display: flex;

    .probability-score-legend-image {
      img {
        cursor: pointer;
        width: 26px;
        //height:26px;
      }

      margin-top: 3px; //added extra for proper fit
      margin-right: 5px; //spacing
      margin-bottom: 5px; //spacing
    }

   
    .info-img-only {
      margin: 30px !important;
      img {
        cursor: pointer;
        width: 25px;
        height:25px;
       
        
      }

      margin-right: 5px; //spacing
      margin-bottom: 5px; //spacing
    }

    
    }
  }
  .table-legend-only {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    margin-bottom: 5px; //spacing

    .table-legend-item {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-family: "Open Sans";
    }
}
.tooltip-icon{
  align-self: end !important;
  height: 40px !important;
  width: 40px !important;
  margin-left: 2rem !important;
  color: #b0c4d4 !important;
}
.filter-sort-combine{
  display: flex;
  width: 67%;
  gap: 2rem;
  padding-top: 9px;
}

@media only screen and (max-width: 1263px) {
  .dash-header-flex-box {
    display: flex;
    flex-direction: column;

    justify-content: flex-start; //reset
    align-items: flex-start; //reset

    width: 100%;
  }
}

@media only screen and (max-width: 805px) {
  .filter-sort-combine{
    width: 100%;
    gap: 1rem;
    padding-top: 0px !important;
    
  }
  
  .outer-grid{
    gap: 1.5rem !important;
    margin-top: 2px !important;
    flex-direction: column !important;

  }
  .inner-grid{
    align-self: center;
    width: 75%;
  }
  .inner-grid2{
    align-self: center;
    width: 75%;

  }
  .filterby{
    width: 100% !important;

  }
  .sort-by{
    width: 100% !important;

  }
  .dash-header-flex-box {
    .flex-item-one {
      flex-direction: column;
      width: 100%;
    }
  }
  .tooltip-icon{
    margin-left: 1rem !important;
    height: 30px !important;
    width: 30px !important;
  }
}

//table legend and info
@media only screen and (max-width: 416px) {
  .filter-sort-combine{
    display: flex !important;
    // width: 67%;
    padding-top: 0px !important;
    
  }
  .dash-header-flex-box {
    .flex-item-two {
      display: flex;
      width: 100%;

      .table-legend-only {
        display: flex;
        flex-direction: column;
        // align-items: center;
        align-items: flex-start;

        margin-bottom: 5px; //spacing

        .table-legend-item {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 13px;
        }
      }
    }
  }
}
