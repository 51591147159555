.marquee-container {
  width: 100%; /* Set the width as desired, can be a fixed width or percentage */
  overflow: hidden; /* Ensures text is hidden outside the container */
  white-space: nowrap; /* Prevents text from wrapping */
  box-sizing: border-box;
  // border: 1px solid #000; /* Optional: for visual debugging */
  text-align: center;
}

.marquee-text {
  display: inline-block;
  font-weight: bold;
  color: #b0c4d4;
  white-space: nowrap;
  // animation: marquee 25s linear infinite;
  font-size: 20px;
}

@media (max-width: 768px) {
  .marquee-text {
    font-size: 20px;
    animation: marquee 10s linear infinite;
  }
}

// @media (min-width: 744px) {
//   .marquee-text {
//     font-size: 20px;
//     animation: marquee 20s linear infinite;
//   }
// }

@media (min-width: 1440px) {
  .marquee-text {
    font-size: 25px;
  }
}

@keyframes marquee {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}
