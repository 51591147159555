.peer-comparison {
  padding: 40px;

  .navbar-container {
    margin-bottom: 20px;
  }

  h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 20px;

    th, td {
      border: 1px solid #ddd;
      padding: 8px;
      text-align: left;
    }

    th {
      background-color: #f2f2f2;
      font-weight: bold;
    }

    th:first-child {
      border-top-left-radius: 20px;
    }

    th:last-child {
      border-top-right-radius: 20px;
    }


    tr:nth-child(even) {
      background-color: #f9f9f9;
    }

    tr:hover {
      background-color: #f1f1f1;
    }

    th {
      padding-top: 12px;
      padding-bottom: 12px;
      background-color:#2d4890;
      color: white;
    }
  }

  .graph {
    width: 100%;
    height: 500px; // Adjust height as needed
    margin-top: 20px;
    fill: transparent;

    .plot-container {
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.peer-comparison {
  padding: 40px;

  .table-container {
    overflow-x: auto;
    border-color: transparent;
  }

  .graph-and-legend-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .legend-container {
    margin-bottom: 20px; // Space between legend and graph
    text-align: center;

    ul {
      list-style-type: none;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      li {
        margin: 0 10px;
      }
    }
  }

  .graph-container {
    overflow-x: auto; // Enable horizontal scrolling for the graph container
    -webkit-overflow-scrolling: touch; // Smooth scrolling for iOS
    width: 100%;
    border-radius: 20px;
  }

  .graph {
    width: 100%;
    min-width: 800px; // Set a fixed width for the graph so that it doesn't shrink on small screens
    height: 100%;
  }

  @media (max-width: 768px) {

    padding: 20px;

    .graph-container {
      // overflow-x: scroll; // Enable horizontal scrolling on mobile
      position: absolute;
      // overflow-x: auto;
      height: auto;
    }

    .graph {
      min-width: auto; // Maintain the graph width to enable scrolling
      padding: 0;
      width: 380;
      height: 365;

    }

    table {

      th,
      td {
        font-size: 12px;
      }
    }

  }

}