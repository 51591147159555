// This is for search
.search-box {
  width: 100%;
  position: relative;
  display: flex;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 20px;
}

// .card-box{
//   margin-top: 20px;
//   margin-left: 20px;
//   margin-right: 20px;
//   margin-left: 20px;
//   border-radius: 20px;
// }
.search-input {
  // color: #f0f0f0;
  width: 100%;
  padding: 10px;
  border: 4px solid #111d5e;
  border-radius: 10px 0 0 10px;
  border-right: none;
  outline: none;
  font-size: 15px;
  // color: tomato;
  color: black;
  background: none;
}

.search-button {
  color: #f0f0f0;
  text-align: center;
  height: 51px;
  width: 40px;
  outline: none;
  cursor: pointer;
  border: 4px solid #111d5e;
  border-radius: 0 10px 10px 0;
  border-left: none;
  background: none;
  font-size: 20px;
  border-left: 4px solid #111d5e;
}
.empty-box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20vh;
  
}


.search-results {
  margin-top: 10px;
}


.disclaimer{
  margin-top: 5px;
  margin-bottom: 15px;
  padding-right: 24px;

  p{
    font-weight: bold;
    font-size: 14px;
    color:red;
  }
}
.semantic-chatbot-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid #2d4890;
  border-radius: 8px;
  background-color: #f9f9f9;
  width: 100%;
  max-width: 97%;
  margin: 0 auto;
  height: 38rem;
}

.left-side {
  width: 25%;
}

.chat-container {

  height: 100%;
  border: 1px solid #453e3e;
  border-radius: 4px;
  padding: 10px;
  background-color: #f0f0f0;
  overflow-y: auto;
  padding: 20px;
}
.chat-container::-webkit-scrollbar-thumb {
  width: 100px !important;
  background: #888 !important;
  border-radius: 4px !important;
}
.right-side {
  width: 75%;
  margin-left: 20px;
}

.metadata-box {
  display: flex;
  align-items: center;
  justify-content: center; 
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  overflow-y: auto;
}

.news-response {
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: #333;
  line-height: 1.6;
  white-space: pre-wrap;
  background: #f4f4f4;
  overflow-y: auto;
}

.response-list-item {
  margin-bottom: 10px; /* Space between items */
}
.no-data-box {
  background-color: #f8d7da; /* Light red background for emphasis */
  color: #721c24; /* Dark red text color for contrast */
  border: 1px solid #f5c6cb; /* Light red border */
  border-radius: 8px; /* Rounded corners */
  padding: 15px; /* Space inside the box */
  text-align: center; /* Center align text */
  font-size: 16px; /* Increase font size */
  font-weight: bold; /* Bold text */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  
}


.chat-container {
  padding: 20px;
}

@media only screen and (max-width: 768px) {
  .disclaimer{
    padding-left: 24px;
    p{
      font-size: 10px;
    }
}
.semantic-chatbot-container{
  height: 100%;
  flex-direction: column;
}
.chat-container {

  height: 100% !important;
  border: 1px solid #453e3e !important;
  border-radius: 4px !important;
  padding: 10px !important;
  background-color: #f0f0f0 !important;
  overflow-y: auto !important;
  padding: 20px !important;
}
.right-side{
  width: 100%;
  margin-left: 0px !important
}
.left-side {
  width: 100%;
  height: 30rem;
  margin-bottom: 1rem;
}
.metadata-box{
  background-color: #f0f0f0;
}


}