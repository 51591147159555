.backtesting-table, .historical-table{
    background-color: white;
    padding: 1rem 2rem;
    border-radius: 20px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
    // overflow-y: scroll;
    .title{
        font-size: 20px;
        font-weight: 600;
        color: #000000;
        margin-bottom: 20px;
    }
    table,.table1 {
        width: 100%;
        border-collapse: collapse; // Collapses borders into a single border
        border-spacing: 0; // Removes default spacing between cells
        border-radius: 20px !important; // Rounds the corners of the table
        overflow-y: scroll;
        th, td {
          padding: 15px 35px; // Adds padding inside cells
          text-align: center; // Aligns text to the left
          border-bottom: 1px solid #ddd; // Adds a bottom border to each cell
        }
    
        th {
          background-color: #062880; // Background color for headers
          color: white; // Text color for headers
          font-weight: 700; // Makes header text bold   
        }
 
        thead {
         th:first-child {
           border-top-left-radius: 20px;
         }
     
         th:last-child {
           border-top-right-radius: 20px;
         }
       }
 
        tr:nth-child(even) {
          background-color: #f2f2f2; // Alternating row colors for better readability
        }
    
        tr:hover {
          background-color: #ddd; // Highlights row on hover
        }
        tr:last-child
 
        .row-title{
         font-weight: 500;
         font-size: 1.15rem;
        }
     }
}
