.trending-section {
  .outer-div-cont{
    display: grid; /* Enable CSS Grid */
    grid-template-columns: repeat(1, 1fr); /* Create 2 equal columns */
    gap: 20px; /* Space between grid items */
  }
  .no-data{
    
    // background-color: #f8d9dc; /* Light red background for emphasis */
    color: #721c24; /* Dark red text color for contrast */
    // border: 1px solid #f5c6cb; /* Light red border */
    border-radius: 8px; /* Rounded corners */
    padding: 15px; /* Space inside the box */
    font-size: 13px; /* Increase font size */
    font-weight: bold; /* Bold text */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    width: fit-content;
    margin-inline: auto;
  }
  background: #f3f3f9;
  min-height: 90vh;
  padding: 0 3%;
  display: flex;
  flex-direction: column;

  ::-webkit-scrollbar {
    width: 5px; /* Width of the vertical scrollbar */
    height: 5px; /* Height of the horizontal scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color of the tracking area */
  }

  ::-webkit-scrollbar-thumb {
    // background-color: #c1c1c1; /* Color of the scroll thumb */
    background-color: #8a8a8a; /* Color of the scroll thumb */
  }
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10; /* Ensure this is below the PriceCard when active */
  }
//not working..
  .column2{
    width: 50%;
    min-height: 90vh;
  }
  .info-box {
    display: flex;
    min-height: 192px;//not working..
    flex-direction: column;
    // gap: 0.5rem;
    width:20%;
    position: absolute;
    z-index: 20; /* Above the modal-backdrop */
    // background-color: white;
    // border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    .info-box-content{
      display: flex;
      // flex-direction: column;
      gap: 0.5rem;
      min-height: 192px;
      padding: 0.5rem;
      background-color: white;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    .info-box-bottom{
      // text-align: center;
      // background-color: darkgray;
      display: flex;
      gap:0.5rem;
      align-items: center;
      justify-content: center;
      color:#fe5f55;
      background-color: #d9d9d9;
      border-bottom-left-radius: 5px;//10px;
      border-bottom-right-radius: 5px;
      span{
        margin-bottom: 0.1rem;
      }
    }
  }
  .link{
    display: flex;
    justify-content: end;
    padding: 1rem 3rem 0 0;
    font-size: 17px;
  }
  .grey-box {
    background-color: #00000012;
    padding: 1rem;
    margin: 1rem;
    border-radius: 0.2rem;//1rem;
  }

  .three-pane-split {
    flex-grow: 1;
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.5rem;

    .price-pane {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 0.35rem;
    }

    .events-pane {
      display: flex;
      flex-basis: 50%;
      flex-direction: column;
      // background-color: var(--Fabric-Color-Pallete, #fff);
      // padding: 0.5rem;
      font-weight: 700;
      // margin:10px;
      border-radius:0.5rem ;
      filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.34));

      .heading {
        font-weight: 700;
        text-align: center;
        background-color: var(--Fabric-Color-Pallete, #fff);
        filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.34));
        padding: 0.5rem;
        border-radius: 0.5rem;
      }

      .events-position {
        display: flex;
        justify-content: center;
        position: relative;
        width: 100%;
        flex-grow: 1;
        aspect-ratio: 5/2;
        border-radius: 0rem;
        font-weight: bold;
        margin-top: 10px;

        .events-flex {
          position: absolute;
          border-radius: 0rem;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          height: 100%;
          overflow-y: auto;
          scroll-snap-type: y mandatory;
        }

        .events-flex > * {
          scroll-snap-align: start;
        }
      }
    }

    .trending-wrapper {
      display: flex;
      flex-direction: column;
      // justify-content: space-around;
      margin: 1px;
      gap: 0.8rem;
    }

    .container-events {
      height: 28px !important;
      background-color: #ffffff;
      padding: 1rem;
      // max-height: fit-content;
      // max-width: 100%;
      border-radius: 0.5rem;
    }

    .stock-select {
      position: relative;
      width: 17rem;
      margin-top: 2rem;
      background: linear-gradient(
        77.46deg,
        #77a9f0 0%,
        #d76d77 49%,
        #fe5f55 98%
      );
      border-radius: 10px;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
      padding: 2px;
    }

    .stock-options {
      position: absolute;
      z-index: 1;
      margin-top: 0.25rem;
      background-color: rgba(255, 255, 255, 0);
      border: 1px solid #d1d5db;
      border-radius: 0.375rem;
      box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
      max-height: 2.5rem;
      overflow: hidden;
    }
    .news-count-card {
      width: 50%;
      flex-grow: 1;
      background-color: var(--Fabric-Color-Pallete, #fff);
      filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.34));
      padding: 1rem 0.5rem;
      border-radius: 0.5rem;
      max-height: 28rem;

      .title {
        font-size: 1.4rem;
        font-weight: 700;
        text-align: center;
        margin-bottom: 0.3rem;
      }

      .news-list {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-top: 0.5rem;
        padding: 0 1rem;
        overflow-y: auto;
        max-height: 90%;

        .news {

          a {
            text-decoration: none;
            color: var(--brand-brand-fabric-blue, #2d4890);
          }

          .ticker {
            // color: #484454;
            font-size: 1rem;
            font-weight: 700;
            margin-bottom: 0;
          }
          .count-details {
            font-size: 1rem;
            font-weight: 400;
            gap:0.5rem;
            padding-bottom: 1rem;
          }
        }
      }
    }

    .top-stories-card {
      flex-grow: 1;
      background-color: var(--Fabric-Color-Pallete, #fff);
      filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.34));
      padding: 0.5rem;
      border-radius: 0.5rem;
      // margin: 3.5rem 0 0.5rem 0;
      width: 50%;
      max-height: 28rem;
      a {
        text-decoration: none;
        color: var(--brand-brand-fabric-blue, #2d4890);
      }

      .title {
        font-size: 1.4rem;
        font-weight: 700;
        text-align: center;
        margin-bottom: 0.6rem;
      }

      .stories-list {
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
        margin-top: 1rem;
        padding: 0 1rem;
        gap:2.5rem;
        overflow-y: auto;
        max-height: 90%;

        .story {
          .date-time {
            color: #626262;
            font-size: 0.7rem;
            font-weight: 400;
          }

          .story-title {
            // color: #484454;
            font-size: 1rem;
            font-weight: 700;
          }
        }
      }
    }
    .news-stories-pane {
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      gap: 0.5rem;
      padding-top: 0;
      border-radius: 0.5rem;
      // margin: 10px;
      .only-desktop{
        background: linear-gradient(77.46deg, #77A9F0 0%, #D76D77 49%, #FE5F55 98%);
        padding: 3px;
        border-radius: 0.5rem;
      }
      
    }
  }

  .footer-disclaimer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    // padding: 1rem 0;
    font-size: 0.7rem;
    font-weight: 600;
  }
}
.text-red-price-change{
  color: red;
  border-radius: 5px;
  padding: 5px 8px 5px 8px;
  width: 40%;
  background-color: #ffdccc;

}
.text-green-price-change{
  color: green;
  border-radius: 5px;
  padding: 5px 8px 5px 8px;
  width: 40%;
  background-color: #d0fccc;

}
.text-red{
  border-radius: 5px;
  padding: 5px 8px 5px 8px;
  // width: max-content;
  background-color: #ffdccc;
}
.text-green{
  border-radius: 5px;
  padding: 5px 8px 5px 8px;
  // width: max-content;
  background-color: #d0fccc;
}
.text-yellow{
  color: rgb(255, 165, 0);
  border-radius: 5px;
  padding: 5px 8px 5px 8px;
  // width: max-content;
  background-color: rgb(255, 245, 178);

}
.ticker-name-trending{
  cursor: pointer;
}
.int-value{
  text-align: -webkit-center;
}
.coloumn-1-trending{
  width: 50%;
}


@media (min-width: 768px) {
  .outer-div-cont{
    // width: max-content;
    display: grid; /* Enable CSS Grid */
    grid-template-columns: repeat(2, 1fr) !important; /* Create 2 equal columns */
    gap: 5px !important; /* Space between grid items */
    height: 1400px; /* Set container height to 1000px */
    grid-auto-rows: minmax(0, 1fr); /* Make rows flexible and evenly distributed */
  }
  .story-news-combo{
    // height: 90% !important;
    display: flex;
    gap: 1rem;
  }
  .trending-section {
    .three-pane-split {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media (max-width: 768px){
  .grey-box {
    margin: 0rem !important;
  }
  .price-pane{
    margin-left: -8px !important;
  }
  .story-news-combo{
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .news-count-card{
    overflow: hidden !important;
    width: 100% !important;
  }
  .top-stories-card{
    overflow: hidden !important;
    width: 100% !important;
  }
.trending-price-change-card .price-grid {
  font-size: 11px;
  gap: 0.3rem;
}
.outer-div-cont{
  display: grid; /* Enable CSS Grid */
  grid-template-columns: repeat(2, 1fr); /* Create 2 equal columns */
  gap: 20px; /* Space between grid items */
}
.column2{
  width: 100% !important;
}
}
.quotes-link{
  // font-weight:400;
  color: Black!important;
  font-weight: 700;
  text-decoration: none!important;
}
.quotes-link:hover{
  color:#1d1d1de0!important;
  font-weight: 800;
}
