.mutual-funds-split {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.no-data-alerts-graph{
  text-align: center; /* Use hyphen instead of camelCase */
  font-size: 20px;
  color: #606060; /* Dark gray */
  margin-top: 20px;
  font-weight: bold;
  background-color: #f7f7f7; /* Light gray background */
  border: 1px solid #d3d3d3; /* Border color */
  border-radius: 8px; /* Rounded corners */
  padding: 15px; /* Padding for better spacing */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: opacity 0.5s ease, visibility 0.5s ease; /* Transition for fade effect */
}





.search-mf-name-div {
  display: flex;
  align-items: center; /* Align items vertically center */
  justify-content: space-between; /* Align items to the left */
}

.search-mf-name-div p {
  margin: 0; /* Remove default margin */
  font-weight: bold; /* Make the label bold */
  margin-right: 10px; /* Space between the label and input */
  font-size: 16px; /* Adjust font size for readability */
}

.search-mf-name-desktop {
  margin-right: 8rem;
  padding: 5px 10px; /* Add some padding inside the input */
  border: 1px solid #ccc; /* Border color */
  border-radius: 4px; /* Rounded corners */
  width: 200px; /* Set a fixed width for the input */
  font-size: 14px; /* Adjust font size for the input text */
  transition: border-color 0.3s ease; /* Smooth border color transition on focus */
}
.search-mf-name-mobile{
  display: none;;
}

.search-mf-name:focus {
  outline: none; /* Remove default focus outline */
  border-color: #007bff; /* Change border color on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Add a shadow effect on focus */
}

.select-box-mutual {
  padding: 20px;
  text-align: center;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin: 10px auto;
  width: 80%;
  max-width: 500px;
}

.mutual-funds-table {
  background-color: white;
  padding: 1rem 1rem;
  border-radius: 15px;
  max-height: 350px;/*  */
  overflow-y: scroll;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

  border: 1px solid #6681c6;
  
  .sort-dropdown {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: flex-end;
    position: relative;
  }
 
  .bi-funnel {
    cursor: pointer;
    margin-left: 8px;
  }

  .sort-options {
    position: absolute;
    top: 100%;
    /* left: 0; */
    background: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;
    width: max-content;
  }

  .sort-option {
    padding: 8px 16px;
    cursor: pointer;
  }

  .sort-option:hover {
    background: #f0f0f0;
  }

  /* Making the table head blue colored */
.table thead { 
  background-color: #062880;
  color: white;
  font-weight: 700;
  /* border-radius: 20px 20px 0 0; */
}
}


.mutual-funds-graph {
  background-color: white;
  padding: 2rem 0;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  /* overflow-x: scroll; Ensuring horizontal scroll for overflowing content */
  overflow-y: hidden;
}

.mutual-funds-graph > canvas {
  margin: 0 auto;
  /* display: block;
  max-width: 100%; */
}

.mutual-fund-alerts {
  background-color: white;
  padding: 2rem 1rem;
  border-radius: 15px;
  max-height: 320px;
  overflow-y: scroll;
  margin: 1rem 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  .title {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: center;
  }
}
/* Mobile responsiveness */
@media screen and (max-width: 1024px) {
  .search-mf-name-mobile{
    display: flex;
    margin-right: 8rem;
    padding: 5px 10px; /* Add some padding inside the input */
    border: 1px solid #ccc; /* Border color */
    border-radius: 10px; /* Rounded corners */
    width: 100%; /* Set a fixed width for the input */
    font-size: 14px; /* Adjust font size for the input text */
    transition: border-color 0.3s ease; /* Smooth border color transition on focus */
  }
  .search-mf-name-desktop{
    display: none;
  }
  .mutual-funds-split {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    gap: 10px;
    padding: 10px;
  }

  .mutual-funds-table {
    width: 100%;
    overflow-x: auto;
    overflow-y: auto; /* Ensure vertical scroll for long tables */
    /* max-height: 300px; Limit height for mobile */
    font-size: 10px;
  }

  .mutual-funds-graph {
    width: 100%;
    height: 500px; /* Adjust height based on content */
   
  }
  
  .mutual-funds-graph > canvas {
    margin: 0 auto;
    max-width: 100%;
    max-height: 400px;
    overflow: auto;
  }
  

   Line{
    height: 400px;
    width: 90%;
    font-size: 10px;
  }

  .mutual-fund-alerts {
    width: 100%;
    /* max-height: 300px; Adjust max height for mobile */
    font-size: 10px;
  }
}

/* //mobile screens */
@media screen and (max-width: 768px) {
  .search-mf-name-mobile{
    width: 100%;
    height: 38px;
    border-radius: 10px;
  }
  .search-mf-name-desktop{
    display: none;
  }
  
  .mutual-funds-split{
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    gap: 10px;
    padding: 10px;
  }

  .mutual-funds-graph > canvas {
    margin: 0 auto;
    display: block;
    max-width: 100%;
    min-height: 300px;
    overflow-y: scroll;
    font-size: 8px;
    height: 324px;
    width: 519px;
  }

  .mutual-funds-table{
    width: 100%;
    overflow-y: auto; /* Horizontal scroll for overflowing content */
    overflow-x: auto; /* Vertical scroll for long table */
    max-height: 300px; /* height limit */
    font-size: 12px;
  }

  .mutual-funds-graph{
    width: 100%;
    min-height: 350px;
    height: 366px;
  }

  .mutual-fund-alerts{
    width: 100%;
    max-height: 300px;
    font-size: 10px;
  }

  .title{
    font-size: 1rem;
  }
}