.ticker-filter {
    position: relative;  
    padding: 0.5rem 1rem;
    text-align: center;
    background-color: transparent;  
    filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.34));
    padding: 0rem;
    border-radius: 0.5rem;
    // max-width: 22rem;
  
    .ant-input {
      background-color: rgba(0, 0, 0, 0.0705882353);;  
      color: #2d4890;  
      padding-right: 2.5rem;
    }
  
    .ant-input::placeholder {
      color: #2d4890;
      opacity: 1;
      text-align: left;
    }
  
    /* Search Icon Styling */
    .search-icon {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 18px;
      color: #2d4890;
      pointer-events: none; 
    }
  }
  