.newsletter-dialog {
  width: 90%;
  margin: auto;
  border-radius: 2rem;
  background: var(--fabric-blue-fabric-blue-50, #eaedf4);
  border-bottom: transparent;
  box-shadow: 4px 4px 100px 0px rgba(0, 0, 0, 0.12);

  &::backdrop {
    background: rgba(42, 42, 42, 0.5);
  }
  .close-div {
    width: 100%;
    .close-button {
      display: block;
      margin-left: auto;
      margin-right: 1rem;
      padding: 0.5rem;
      font-size: 1rem;
      font-weight: 700;
      background: transparent;
      border: transparent;
    }
  }

  .title {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 700;
  }

  form {
    & > * {
      margin: 0.7rem 0;
      padding: 0 5%;
    }

    input,
    select {
      width: 100%;
      padding: 0.5rem;
      border-radius: 0.5rem;
    }

    label {
      display: block;
    }

    .stock-title, .tnc-title {
      font-size: 1.2rem;
      font-weight: 700;
    }

    .tnc {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      input {
        width: 1rem;
        aspect-ratio: 1/1;
        margin-right: 1rem;
        padding: 0.5rem;
      }
    }

    .submit {
      width: 100%;
      padding: 0.5rem 0;
      margin-bottom: 0;
      font-size: 1.5rem;
      background: #fe5f55;
      color: white;
      border: transparent;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
    }
  }
}


@media (min-width: 768px) {
  .newsletter-dialog {
    width: 70%;
  }
}
