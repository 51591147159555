.sort-menu {
  #demo-customized-button {
    text-transform: none;
   font-size: 15px;
    font-weight: 500;
    color: #575c5c;
    padding: 0;
    margin-top: -2px;
    &:hover {
      background-color: transparent;
    }
  }
}

.customized-menu-sort {
  .statusDots {
    height: 17px;
    width: 17px;
    border-radius: 50px;
  }
  hr {
    &:last-child {
      display: none;
    }
  }
}



@media only screen and (max-width: 991px) {
  .sort-menu {
    #demo-customized-button {
     font-size: 14px;
    }
  }
}