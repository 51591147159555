.tab-container {
  margin-top: -15px !important;
  display: flex;
  gap:1rem;
  margin: 1rem 0;
  border-bottom: 2px solid #f2f2f5;
  .tab {
    /* Styles for each tab */
    padding: 5px;
    cursor: pointer;
    font-size: 1rem;
    color:#062880;
    &:hover {
      color: #FE7A71;
      border-bottom: 2px solid #FE7A71;
    }
    &.active {
      border-bottom: 2px solid #FE7A71;
      color: #FE7A71;
    }
  }
}
.financial-insights-container {
  // display: flex;
  // flex-direction: column;
  // gap: 2rem;
  // justify-content: center;
  .financial-panel {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  /* Styles for the top section */
  .financial-insights-top {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    height: 55vh;
    /* Styles for the middle section */
    .table-container {
      width:60%;
      background-color: #ffffff;
      // overflow: auto;
    }

    /* Styles for the right section */
    .insights-container {
      width:40%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 2rem;
      text-align:justify;
      border-radius: 5%;
      overflow: auto;
      h2 {
        font-size: 1.5rem;
        font-weight: 700;
        padding: 0.5rem;
        text-align: center;
      }
      ul {
        font-size: 1rem;
        font-weight: 500;
        padding: 1rem;
      }
      // .report-character{
      //   font-weight: 500;
      //   font-size: 1rem;
      //   span{
      //       font-weight: 900;
      //       color: #4CAF50;
      //   }
      // }
    }
  }
}

/* Styles for the bottom section */
.financial-insights-bottom {
  .graphs-container {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    // width: 100%;
    background-color:#ffffff;
    align-items: center;
    .graph {
      width: 50%;
      // background-color:#ffffff;
      padding: 2rem;
      // border-radius: 5%;
    }
  }
}
@media screen and (max-width: 1024px){
  .financial-insights-container {
    display: block;
    .financial-insights-top {
      flex-direction: column;
      gap: 2rem;
      height: auto;
      .table-container {
        width: auto;
      }
      .insights-container {
        width:auto;
      }
    }
    .financial-insights-bottom {
      .graphs-container {
        flex-direction: column;
        gap: 2rem;
        .graph {
          width: auto;
        }
      }
    }
  }
}
.no-data {
  color: gray;
  text-align: center;
  // font-style: italic; 
  font-size: large;
  margin: 20px 0; 
}


@media screen and (max-width: 768px) {
  .tab-container{
    display: flex;
    flex-wrap: wrap; 
    width:auto;
    .tab {
      width: auto;
    }
  }
  .financial-insights-container {
    flex-direction: column;
    gap: 2rem;
    .financial-insights-top {
      flex-direction: column;
      gap: 2rem;
      height: auto;
      .table-container {
        width: 100%;
      }
      .insights-container {
        width: 100%;
      }
    }
    .financial-insights-bottom {
      .graphs-container {
        flex-direction: column;
        gap: 2rem;
        .graph {
          width: 100%;
        }
      }
    }
  }
}