.overview-container {
  padding: 20px;
  padding-top: 0% !important;
  display: flex;
  height: 80vh;
  flex-direction: column;
  .top-content-container{
    display: flex;
    justify-content: space-between;
    gap:1.5rem;
    .left-content-container {
      display:flex;
      flex-direction: column;
      width: 50%;
      .summary-container{
        display: flex;
        flex-direction: column;
        padding: 1rem 0;
        .summary-title{
          font-size: 25px;
          font-weight: 700;
          font-family: DM Sans;
        }
        .content {
          text-align: justify;
          font-size: 1rem;
          padding: 1rem 0 0;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3; /* Limit to 3 lines */
          -webkit-box-orient: vertical;
        }
        .show-full-content {
          -webkit-line-clamp: unset; /* Remove the truncation */
        }
        .read-more-button {
          background: none;
          border: none;
          color: #FE5F55;
          cursor: pointer;
          padding: 0;
          font-size: 1rem;
          text-decoration: none;
          width:fit-content;
        }
      }
      .sentiment-container{
        overflow-y:auto;
        margin-bottom: 20px;
        box-shadow: 0 12px 14px rgba(0, 0, 0, 0.1);
        background-color: #F2F4F8;
        border: 2px solid #BEC6DD;
        border-radius: 26px;
        scrollbar-width: thin;
        padding:1rem;
        .sentiment-title{
          display: flex;
          gap:1rem;
          align-items: center;
          .heading{
            font-size: 25px;
            font-weight: 600;
            margin-bottom: 0.2rem;
            font-family: DM Sans;
          }
          span{
            font-size: 20px;
            font-weight: 600;
            padding: 0 3rem;
            border-radius: 5px;
          }
        }
      }
    }
    .right-content-container {
      padding-bottom: 40px !important;
      margin-top: 10px;
      font-size:15px;
      width: 50%;
      .chatbot-container {
        // overflow: hidden;
        height: 100%;
        font-family: Arial, sans-serif;
        .chatbot-header {
          background: linear-gradient(77.46deg, #77A9F0 0%, #D76D77 49%, #FE5F55 98%);
          color: #2D4890;
          text-align: center;
          font-size: 25px;  
          font-weight: 700px;
          border-radius: .5rem;
          gap: 6px;
          padding:0.4rem;
          margin: 1rem 0;
          display: flex;
        
          span{
            width:100%;
            // background-color: #fff;
            background: linear-gradient(90deg, #e9eff7 0%, #f6e9eb 100%);
            border-radius: .5rem;
            -webkit-filter: drop-shadow(0 2px 10px rgba(0, 0, 0, .34));
            filter: drop-shadow(0 2px 10px rgba(0, 0, 0, .34));
            padding: 0;
            position: relative;
            text-align: center;
          }
        }
        
        .chatbot-body {
          // height: 80%;
          padding: 15px;
          background-color: #fff;
          border-radius: 15px;
          box-shadow: 0 12px 14px rgba(0, 0, 0, 0.1);
          border: 2px solid #c0c4dc;
          overflow-y: auto;
          height: 400px;
          scrollbar-width: thin;
        }
        
        .message {
          margin: 10px 0;
          padding: 10px 15px;
          border-radius: 20px;
          max-width: 70%;
          font-size: 14px;
          line-height: 1.5;
        }
        
        .user-message {
          align-self: flex-start;
          background-color: #e0f7fa;
          color: #004d40;
        }
        
        .bot-response {
          margin-left: auto;
          background-color: #fce4ec;
          color: #880e4f;
        }
        
        .chatbot-body::-webkit-scrollbar {
          width: 5px;
        }
        
        .chatbot-body::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.3);
          border-radius: 10px;
        }
        
        .chatbot-body::-webkit-scrollbar-track {
          background-color: transparent;
        }
        
        .chatbot-button {
          margin: 10px auto;
          width: 60%;
          font-size: 0.75rem;
          text-align: center;
          border: 2px solid #c0c4dc;
          border-radius: 5px;
          padding: 5px 10px 5px 10px;
          cursor: pointer;
          transition: background-color 0.3s ease;
        }
        
        .chatbot-button:hover {
          background-color: #e0e0e0;
        }
        
        .chatbot-footer {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding-top: 10px;
        }
        .input-container{
          margin-left: 5px;
          margin-right: 5px;
          position: relative;
          width: 100%; 
        
        }
        
        .chatbot-textarea {
          align-content: center;
          padding-right: 40px !important;
          padding-left: 20px !important;
          border-radius: 5px;
          height: 3.5rem;
          width: 100%;
          border: none;
          padding: 5px;
          font-size: 13px;
          background-color: #fff;
          white-space: pre-wrap; /* Wrap text and preserve whitespace */
          word-wrap: break-word; /* Break long words to fit the container */
          box-sizing: border-box; 
          box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
          border: 2px solid #c0c4dc;
        }
        .chatbot-submit {
          position: absolute; 
          right: 10px;
          top: 50%; 
          transform: translateY(-50%);
          background: transparent;
          border: none; 
          cursor: pointer;
          padding: 0;
        }
        .chatbot-submit svg {
          fill: #000; /* Change icon color if needed */
          width: 20px; /* Adjust size of the icon */
          height: 20px; /* Adjust size of the icon */
        }
        .response-box{
          max-height: 200px;
          overflow-y: scroll;
          padding: 15px 0 15px 15px;
        }
        .response-box::-webkit-scrollbar {
          width: 4px; 
        }
        
        .response-box::-webkit-scrollbar-thumb {
          background-color: rgba(76, 73, 73, 0.5);
          border-radius: 10px;
        }
        
        .response-box::-webkit-scrollbar-track {
          background-color: transparent;
        }
        .loader{
          position: relative;
        
        }
      }
    }
  }
  .bottom-content-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap:2rem;
    .grid-item {
      display: block;
      overflow-y:auto;
      height: 400px;
      margin-bottom: 20px;
      box-shadow: 0 12px 14px rgba(0, 0, 0, 0.1);
      background-color: #E4E7F0;
      border: 2px solid #BEC6DD;
      scrollbar-width: thin;
    }
    
    .grid-item ul{
      margin: 0px 20px 0px 20px;
      padding-left: 0;
    }
    .grid-item li{
      background-color: #fff;
      padding: 1rem;
      font-family:DM Sans;
      list-style:none;
      margin:1rem 0;
      color: #464553;
      font-weight: 700;
      font-size:15px;
    }
    .grid-item h2 {
      margin: 2rem 0 1rem 2rem !important;
      font-weight: 600;
      font-size: 25px;
      margin-top: 0;
      font-family: DM Sans;
    }
    
    
    .content::-webkit-scrollbar {
      width: 4px; 
    }
    
    .content::-webkit-scrollbar-thumb {
      background-color: rgba(76, 73, 73, 0.5);
      border-radius: 10px;
    }
    
    .content::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }
}


@media (max-width: 768px){
  .overview-container{
    padding: 10px;
    .top-content-container{
      flex-direction: column;
      .left-content-container{
        width: 100%;
        .summary-container{
          .summary-title{
            font-size: 20px;
          }
          .content{
            font-size: 0.9rem;
          }
          .read-more-button{
            font-size: 0.9rem;
          }
        }
        .sentiment-container{
          .sentiment-title{
            .heading{
              font-size: 20px;
            }
            span{
              font-size: 15px;
            }
          }
        }
      }
      .right-content-container{
        width: 100%;
        .chatbot-container{
          .chatbot-header{
            font-size: 20px;
          }
          .message{
            font-size: 12px;
          }
          .chatbot-button{
            font-size: 0.7rem;
          }
          .chatbot-textarea{
            font-size: 12px;
          }
          .response-box{
            max-height: 150px;
          }
        }
      }
    }
    .bottom-content-container{
      grid-template-columns: 1fr;
      .grid-item{
        .grid-item li{
          font-size: 12px;
        }
        h2{
          font-size: 20px;
        }
      }
    }
  }
}