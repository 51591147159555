// Variables
$background-color: #f0f4f8;
$border-color: #e0e7ff;
$text-color: #1e293b;
$positive-color: #22c55e;
$negative-color: #ef4444;

.dashboard {
  padding-top: 2rem;
  padding-left: 3rem;
  padding-right: 3rem;
  margin-bottom: -20px;
  background: linear-gradient(90deg, #e9eff7 0%, #f6e9eb 100%);

  .stock-marquee {
    width: 100%;
    background-color: $background-color;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    overflow: hidden;
    padding: 8px 0;
    margin-bottom: 1rem;

    &-container {
      display: flex;
      width: 100%;
    }

    &-item {
      display: flex;
      animation: marquee 60s linear infinite;
      white-space: nowrap;

      &-content {
        display: flex;
        align-items: center;
        margin-right: 24px;
        font-size: 14px;
        font-weight: 600;
        color: $text-color;

        &-name {
          margin-right: 8px;
        }

        &-logo {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          padding: 4px;
        }

        &-price {
          margin-left: 8px;
          &.positive {
            color: $positive-color;
          }
          &.negative {
            color: $negative-color;
          }
        }
      }
    }

    &-item:hover {
      animation-play-state: paused;
    }
  }

  @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  .flex-on-table-header {
    h6 {
      font-family: "Open Sans";
      font-weight: 500;
    }
  }

  table {
    background-color: #ffffff;
    border-radius: 8px;
    th {
      color: #838889;
      font-family: "Open Sans";
      font-size: 15px;
      font-weight: 500;
      text-align: center;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    td {
      font-size: 15px;
      font-weight: 500;
      font-family: "Open Sans";
      line-height: 25px;
      color: black;
      text-align: center;
      vertical-align: middle;
      button {
        font-size: 15px;
        font-weight: 500;
        font-family: "Open Sans";
        line-height: 25px;
        color: black;
        text-align: center;
        min-width: 100%;
        border-radius: 10px;

        &:focus {
          box-shadow: unset;
        }
      }
    }
    .td_hover:hover {
      text-shadow: 1px 0;
      box-shadow: 1px 1px 3px;
      background-color: whitesmoke;
    }
  }
  .table > :not(caption) > * > * {
    border-bottom-width: 0px;
  }
  .table > :not(:first-child) {
    border-top: 1px solid #dee2e6;
  }
  .table-bordered > :not(caption) > * > * {
    border-width: 0 0px;
  }

  .disclaimer-dashboard {
    font-size: 14px;
    font-weight: bold;
    margin: 0px;
  }
}

#feedback button {
  z-index: 10;
  position: fixed;
  right: -20px;
  transition: 0.3s;
  padding: 15px;
  width: 80px;
  top: 70%;
  text-decoration: none;
  font-size: 10px;
  font-weight: bold;
  background-color: #062880;
  color: white;
  text-transform: capitalize;
  border-radius: 5px 5px 5px 5px;
  vertical-align: top;
  transform: rotate(7deg);
  -ms-transform: rotate(90deg); /* IE 9 */
  -moz-transform: rotate(90deg); /* Firefox */
  -webkit-transform: rotate(90deg); /* Safari and Chrome */
  -o-transform: rotate(90deg);
}

#feedback button:hover {
  right: 0px;
  transform: rotate(0deg);
  -ms-transform: rotate(0deg); /* IE 9 */
  -moz-transform: rotate(0deg); /* Firefox */
  -webkit-transform: rotate(0deg); /* Safari and Chrome */
  -o-transform: rotate(0deg);
}

@media only screen and (max-width: 991px) {
  .dashboard {
    table {
      th {
        font-size: 14px;
      }

      td {
        font-size: 14px;
        button {
          font-size: 14px;
        }
      }
    }

    .disclaimer-dashboard {
      font-size: 10px;
    }
  }
}
.table-responsive {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  height: 580px;
}
.table-responsive::-webkit-scrollbar {
  width: 8px; /* width for vertical scrollbar */
}

.table-responsive::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background color of the track */
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: #d2cfcf; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Roundness of the scrollbar thumb */
  border: 3px solid #f1f1f1; /* Padding around the scrollbar thumb */
}

.table-responsive::-webkit-scrollbar-thumb:hover {
  background: #797878;
}
@media screen and (max-width: 768px) {
  .table-responsive {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    height: 335px;
  }
}
// Media query for smaller screens
@media (max-width: 768px) {
  .stock-marquee {
    &-item {
      &-content {
        font-size: 12px;
        margin-right: 16px;
      }
    }
  }
}
@media screen and (max-width: 464px) {
  .table-responsive {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    height: 254px;
  }
}