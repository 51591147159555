// .candlestick-per-day {
//   max-height: 351px;
//   .background-gray {
//     background-color: #fbfbfb;
//     border-radius: 6px;
//     // pointer-events: none;
//     h6 {
//       font-family: "Open Sans";
//       font-weight: 500;
//       font-size: 14px;
//     }
//   }
// }

// @media only screen and (max-width: 991px) {
//   .candlestick-per-day .background-gray h6 {
//     font-size: 13px;
//   }
// }



  
.candlestick-per-day-graph{

  padding: 10px;
  padding-top:0px;


  .white-box{
    padding: 10px;
    border-radius: 6px;
    background: #fff;
    height: 300px;
    h6 {
      text-align: center;
      font-weight: 500;
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
  }
  }



}



@media only screen and (max-width: 991px) {

.candlestick-per-day-graph .white-box h6 {
    font-size: 13px;
  }

}