.feature-grid-card {
  background: #fff;
  width: 100%;
  // aspect-ratio: 16/10;
  padding: 1rem;
  padding-bottom: 3rem;
  border-radius: 5%;
  box-shadow: 0px 4px 70px 0px rgba(0, 0, 0, 0.12);

  &:hover {
    background-color: #2D4890;
    color: #fff;
    transition: 0.5s;

    .card-subtitle {
      color: #fff;
    }
  }

  .card-icon {
    width: 25%;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: whitesmoke;
    padding: 2%;
    svg {
      width: 100%;
    }
  }

  .card-title {
    margin: 1rem 0;
    font-family: DM Sans;
    font-size: 1.25rem;
    font-weight: 600;
  }

  .card-subtitle {
    color: var(--lavender-lavender-900, #606066);
    font-family: DM Sans;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
  }
}

@media (min-width: 768px) {
  .feature-grid-card {
    background: #fff;
    width: 100%;
    // aspect-ratio: 16/10;
    padding: 1rem;
    padding-bottom: 3rem;
    border-radius: 5%;
    box-shadow: 0px 4px 70px 0px rgba(0, 0, 0, 0.12);

    .card-icon {
      width: 15%;
      aspect-ratio: 1/1;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: whitesmoke;
      padding: 2%;
    }

    .card-title {
      margin: 1rem 0;
      font-family: DM Sans;
      font-size: 1.25rem;
      font-weight: 600;
    }

    .card-subtitle {
      color: var(--lavender-lavender-900, #606066);
      font-family: DM Sans;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem; /* 150% */
    }
  }
}
