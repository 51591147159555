.stock-selection-home {
  background-color: #fcfdfe;
  .title {
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    padding-top: 1rem;
    margin-bottom: 1rem;
  }
  .stock-selection-animation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 1rem 0;
  }
  .img1 {
    width: 60%;
  }
  .img {
    transition: all 0.5s;
  }
  .img2 {
    width: 47%;
  }
  .img3 {
    width: 34.5%;
  }
  .img4 {
    width: 22.5%;
  }
  .img5 {
    width: 21%;
  }
  .img-animation {
    transition: all 1s;
    opacity: 0;
    transform: translateY(-50%);
  }
  .img-animation-start {
    opacity: 100;
    transform: none;
  }
  .subtext {
    display: flex;
    gap: 3rem;
    flex-direction: column;
    padding: 1rem 6rem;
    h5 {
      font-size: 1.5rem;
      font-weight: 500;
      text-align: center;
      margin: 1rem 14rem;
    }
    h1 {
      font-size: 2rem;
      font-weight: 700;
      text-align: center;
      margin: 1rem 14rem;
    }
  }
  .secondary-navbar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    padding: 0 5rem;
    .secondary-navbar-tab {
      padding: 0.5rem 1rem;
      color: #2d4890;
      border-bottom: 3px solid transparent;
      &:hover {
        cursor: pointer;
        border-bottom: 3px solid #fe5f55;
        color: #fe5f55;
      }
      &:active {
        color: #fe5f55;
        border-bottom: 3px solid #fe5f55;
      }
    }
  }
  .divider {
    width: 90%;
    margin: 0 5rem;
  }
  .line-plot{
    display: flex;
    justify-content: center;
    padding: 1rem;
  }
  // .line-chart-img{
  //   padding: 3rem 0;
  //   display: flex;
  //   justify-content: center;
  //   img{
  //       width: 85%;
  //   }
  // }
  .portfolio-section{
    padding: 1rem;
    h1 {
      font-size: 2rem;
      font-weight: 700;
      text-align: center;
      margin: 1rem 0;
    }
    .text-image-split{
      // justify-content :center ;
        display: flex;
        // flex-direction: column;
        gap: 1rem;
        padding: 1rem 5rem;
        align-items: center;
        .text{
            display: flex;
            flex-direction: column;
            gap: 1rem;
            text-align: center;
            padding: 1rem;
            // padding:0rem 5rem;
            h5{
                text-align: justify;
                font-size: 1.25rem;
                font-weight: 700;
            }
        }
        .historical-performance-table{
          .table {
            width: 100%; // Full width table
            border-collapse: collapse; // Remove space between cells
            // border: 2px solid #062880; // Blue border around table
            border-radius: 15px; // Rounded corners for table
            box-shadow: 0 0 10px rgba(0,0,0,0.1);
          
            &.table-striped {
              th{
                padding: 10px 30px; // Padding inside cells
                text-align: center; // Align text to the left
                border-bottom: 1px solid #ddd; // Bottom border for separation
          
                &:first-child {
                  border-top-left-radius: 15px; // Rounded corners for first cell in header
                }
          
                &:last-child {
                  border-top-right-radius: 15px; // Rounded corners for last cell in header
                }
              }
          
              th {
                background-color: #062880;
                color: white; // White text for contrast
                font-weight: bold; // Bold font for headers
              }
          
              td {
                vertical-align: top; // Align content to top of cell
                padding: 10px 30px; // Padding inside cells
                text-align: center; // Align text to the left
                border-bottom: 1px solid #ddd; // Bottom border for separation
              }
            }
          }
        }
    }
  }
  .analysis {
    margin: 2rem 5%;
    .title {
      font-size: 2rem;
      font-weight: 700;
      text-align: center;
      margin: 1rem 0;
    }
    .analysis-filters {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      gap: 2rem;
      padding: 1rem 0;
      margin: 3rem 5rem;
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      .filter {
        padding: 0.5rem 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        label {
          font-size: 1.25rem;
          font-weight: 500;
        }
        select {
          padding: 0.5rem;
          border: 1px solid #2d4890;
          border-radius: 5px;
          background-color: #fff;
        }
      }
      .month-year-div {
        display: flex;
      }
    }
    .line-graph {
      display: flex;
      justify-content: center;
      flex-direction: column;
    
    }
  }
}
.table-graph-forward-testing {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5rem;
  padding: 1rem;
  padding-bottom: 0;

  
}
.data-table-forward-testing{
  box-shadow: 0 0 20px rgba(0,0,0,0.3);
  border-radius: 15px;
}
.graph-forward-testing{
  width: 800px;
  height: 400px;
  padding: 2rem;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}
.normalised-graph {
  margin: 1rem 6rem;
  padding: 3rem;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .stock-selection-home{
    .stock-selection-animation{
      .img1{
        width: 100%;
      }
      .img2{
        width: 75%;
      }
      .img3{
        width: 55%;
      }
      .img4{
        width: 35%;
      }
      .img5{
        width: 30%;
      }
    }
    .subtext{
      padding: 1rem 2rem;
      gap: 2rem;
    }
    .portfolio-section {
      h1{
      font-size: 1.5rem !important;
    }
    .text-image-split{
      padding: 1rem 2rem;
      .text h5{
        font-size: 1rem !important;
      }
    }
  }
   .divider{
    width: auto;
  }
  .title{
    font-size: 1.5rem;
  }
}
  .subtext {
    display: flex;
    gap: 3rem;
    flex-direction: column;
    padding: 1rem 2rem;
    h5 {
      font-size: 1rem !important;
      font-weight: 500;
      text-align: center;
      margin: 1rem 2rem !important;
    }
    h1 {
      font-size: 1.25rem !important;
      font-weight: 700;
      text-align: center;
      margin: 1rem 2rem !important;
    }
  }
  .text-image-split {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 5rem;
    align-items: center;
  }
  .text-image-split .text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }
  .text-image-split .text h5 {
    font-size: 1rem;
    font-weight: 700;
  }
  .table-img {
    width: 100%;
  }
  .backtesting .table-graph-forward-testing {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5rem;
    padding: 1rem;
  }
  .backtesting .table.table-striped td,
  .table-striped th {
    vertical-align: top;
    padding: 15px 10px !important;
    text-align: center;
    border-bottom: 1px solid #ddd;
}
.graph-forward-testing {
    width: 100% !important;
    height: 650px !important;
    padding: 2rem;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  }
  .analysis-filters {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 2rem;
    padding: 1rem 0;
    margin: 0rem 0rem !important;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .analysis .title {
    font-size: 1.5rem !important;
    font-weight: 700;
    text-align: center;
    margin: 2rem 0 2rem  !important;
  }
  .normalised-graph {
    height: 750px !important;
    margin: 1rem 1rem !important;
    padding: 0rem !important;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .historical-performance-table {
    overflow-x: auto !important; 
    max-width: 20rem !important;
    .table.table-striped td {
      padding: 10px 10px !important;
    } 
  }
  
}
