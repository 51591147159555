.landingv2-home-section {
  background: linear-gradient(90deg, #e9eff7 0%, #f6e9eb 100%);
  padding: 0 10%;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  align-items: center;
  overflow: hidden;
  // background-image: linear-gradient(
  //   90deg,
  //   rgba(18, 29, 58, 0.15),
  //   rgba(254, 94, 82, 0.15) 100%
  // );

  .home-title {
    color: var(--brand-brand-fabric-blue, #2d4890);
    // color: #00ddcb;
    font-family: DM Sans;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 4rem;
    z-index: 2;
    text-align: center;
    // color: #ACC2D5;
  }

  .home-subtitle {
    color: #acc2d5;
    // color:#000000;
    font-family: DM Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2rem;
    width: 80%;
    margin: 2rem 0;
    text-align: center;
  }

  .home-button {
    color: #fff;
    padding: 0.5rem;
    border: #fe5f55;
    border-radius: 1rem;
    background: linear-gradient(270deg, #fe7f77 0%, #fe5f55 100%);
    box-shadow: 0px 15px 30px 0px rgba(20, 102, 204, 0.16);
    position: relative;
    z-index: 3;
  }
  .search-ticker {
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
    margin-top: 2rem;
    z-index: 2;
    background: #b3bac3;
    border-radius: 3rem;
    border: 1px solid #1a2a34;

    .search-ticker-input {
      width: 100%;
      // height: 3rem;
      color: #1a2a34;
      font-family: DM Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
      padding: 0.5rem;
      margin-right: 1rem;
      background: none;
      border: 0;
      option {
        color: #1a2a34;
        background: #b3bac3;
        font-family: DM Sans;
        font-size: 18px;
        padding: 0.2rem;
      }
    }

    .search-ticker-input:focus {
      outline: none;
    }
  }

    .rock-img {
        z-index: 0;
        position: absolute;
        left: -25px;
        bottom:0px;
    }
    .insights-section{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .price-change-table{
        position: relative;
        box-shadow: 0px 15px 30px 0px rgba(20, 102, 204, 0.16);
      }
    }
    .insights-text-btn{
      display: flex;
      flex-direction: column;
      gap: 1rem;
      justify-content: center;
      align-items: center;
      width: 100%;
      // margin: 0 2rem;
      .insights-text{
        color: #000000;
        font-family: DM Sans;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2rem;
        margin-bottom: 1rem;
        text-align: center;
        h1{
          font-size: 37px;
          font-weight: 700;
          color:#2d4890;
        }
        p{
          margin-top: 2rem;
          margin-bottom: 0;
          font-weight: 300;
          font-size: 1.15rem;
        }
      }
      // .insights-button{
      //   color: #fff;
      //   padding: 0.75rem;
      //   border: #fe5f55;
      //   border-radius: 1.5rem;
      //   background: linear-gradient(270deg, #fe7f77 0%, #fe5f55 100%);
      //   box-shadow: 0px 15px 30px 0px rgba(20, 102, 204, 0.16);
      //   z-index: 2;
      // }
    }
  .insights-button {
    color: #fff;
    padding: 0.75rem;
    border: #fe5f55;
    border-radius: 1.5rem;
    background: linear-gradient(270deg, #fe7f77 0%, #fe5f55 100%);
    box-shadow: 0px 15px 30px 0px rgba(20, 102, 204, 0.16);
    z-index: 2;
  } 
}
@media (min-width: 768px) {
  .landingv2-home-section {
    min-height: 86vh;
    // background-color: aqua;
    // background: linear-gradient(180deg, #f3f3f9 0%, #eaedf4 100%);
    padding: 0 10%;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    // align-items: flex-start;
    align-items: center;
    overflow: hidden;

    .home-title {
      color: var(--brand-brand-fabric-blue, #2d4890);
      font-family: DM Sans;
      font-size: 4rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 3rem;
      // color: #00f5e0e5;
      // filter: drop-shadow(4px 4px 0px #4cd5ff4d);
    }

    .home-subtitle {
      color: #acc2d5;
      font-family: DM Sans;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: 2rem;
      width: 65%;
      margin: 2rem 0;
      text-align: center;
    }

    .home-button {
      color: #fff;
      padding: 0.5rem;
      border: #fe5f55;
      border-radius: 1.5rem;
      background: linear-gradient(270deg, #fe7f77 0%, #fe5f55 100%);
      box-shadow: 0px 15px 30px 0px rgba(20, 102, 204, 0.16);
      z-index: 2;
    }

    .search-ticker {
      padding: 0.5rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 30%;
      margin-top: 2rem;
      z-index: 2;
      background: #b3bac3;
      border-radius: 3rem;
      border: 1px solid #1a2a34;

      .search-ticker-input {
        width: 100%;
        // height: 3rem;
        color: #1a2a34;
        font-family: DM Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem;
        padding: 0.5rem;
        margin-right: 1rem;
        background: none;
        border: 0;
        option {
          background: #b3bac3;
          color: #1a2a34;
          font-family: DM Sans;
          font-size: 18px;
          padding: 0.2rem;
        }
      }

      .search-ticker-input:focus {
        outline: none;
      }
    }

    .graph-bg {
      // position: relative;
      width: 100%;
      aspect-ratio: 5/1;
      margin-top: -25%;
      margin-bottom: -10%;
      z-index: 1;

      svg {
        aspect-ratio: 2/1;
        transform: scale(2);
        // background-color: aquamarine;
        object-fit: fill;
      }
    }
    .home-insights-section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 5rem;
      .rock-img {
          position: absolute;
          left: 0px;
      }
      .insights-section {
        display: flex;
        align-items: center;

        .price-change-table {
          position: relative;
          box-shadow: 0px 15px 30px 0px rgba(20, 102, 204, 0.16);
        }
      }
      .insights-text-btn {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        align-items: center;
        width: 45%;
        // margin: 0 2rem;

        .insights-text {
          color:#000000;
          // color: #fff;
          font-family: DM Sans;
          font-size: 1.5rem;
          font-style: normal;
          line-height: 2rem;
          margin-bottom: 1rem;
          text-align: center;
          h1{
            font-size: 37px;
            font-weight: 700;
            color:#2d4890;
          }
          p {
            margin-top: 2rem;
            margin-bottom: 0;
            font-weight: 300;
            font-size: 1.15rem;
          }
        }

        // .insights-button {
        //   color: #fff;
        //   padding: 0.75rem;
        //   border: #fe5f55;
        //   border-radius: 1.5rem;
        //   background: linear-gradient(270deg, #fe7f77 0%, #fe5f55 100%);
        //   box-shadow: 0px 15px 30px 0px rgba(20, 102, 204, 0.16);
        //   z-index: 2;
        // }
      }
    }
  }
  .insights-button {
    color: #fff;
    padding: 0.75rem;
    border: #fe5f55;
    border-radius: 1.5rem;
    background: linear-gradient(270deg, #fe7f77 0%, #fe5f55 100%);
    box-shadow: 0px 15px 30px 0px rgba(20, 102, 204, 0.16);
    z-index: 2;
    justify-items: center;
    margin-left: 37%;
  }
}
@media (max-width: 768px) {
  .landingv2-home-section {
      padding-bottom: 10rem;
    .rock-img {
          position: absolute;
          width: 50%;  // Adjust the percentage based on the desired size
          height: auto; // Maintain aspect ratio
          left: -10px;  // Adjust position if needed
      }
     
  }
  .row-single-card {
    margin-left: 9rem!important;
    z-index: 1;
    margin-top: -1rem!important;
  }
  
  .row-double-card {
    margin-right: 9rem!important;
    z-index: 1;
    margin-top: -1rem!important;
  }
  
  .landingv2-home-section .stock-card {
    padding: 10px;
    width: 100% !important; 
    box-sizing: border-box; 
  }

  .landingv2-home-section .stock-title {
    font-size: 14px;
  }

  .landingv2-home-section .stock-data {
    display: flex;
    gap: 0px;
    justify-content: space-around;
    width: 75%;
  }

  .landingv2-home-section .stock-value {
    max-width: 70px; 
  }
}

.trending-card-section {
  background: linear-gradient(90deg, #e9eff7 0%, #f6e9eb 100%);
  .confidence-section{
    gap:5rem;
  }
  .selection-section {
    .selection-title h1 {
      font-size: 1.75rem;
      font-weight: 700;
      // color: #fff;
      color: #2d4890;
      text-align: center;
    }
    .selection-content {
      // color: #fff;
      color: black;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      margin: 1rem 0;
      .selection-content-item {
        display: flex;
        gap: 2rem;
        align-items: center;

        .icon {
          background: #fff;
          border-radius: 20px;
          backdrop-filter: blur(10px);
          padding: 1rem;
          border: 1px solid #3a3a3c;
        }
        .links{
          text-decoration: none !important; 
          color: black;
        }
        .links:hover{
          text-decoration: none !important; 
          color: black;
          transform:  translateX(10px) scale(1.05);
        }
      }
    }
  }
  .image-section {
    display: flex;
    // flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    // margin-top: 8%;

    .selection-image {
      display: flex;
      flex-direction: column;
      z-index: 1;
      gap: 0rem;
      max-width: 100%;
      margin-top: -90px;

      img {
        max-width: 100%;
        height: auto;
      }

      .img2-3 {
        display: flex;
        flex-direction: row;
        gap: 1rem;

        .img2,
        .img3 {
          flex: 1;
        }
      }
    }
    .selection-bg-img {
      img {
        position: absolute;
        right: 0;
        width: 60%;
        height: 70%;
      }
    }
  }
}

@media (min-width: 768px) {
  .trending-card-section {
    background:linear-gradient(90deg, #e9eff7 0%, #f6e9eb 100%);
    .selection-section {
      .selection-title h1 {
        font-size: 2rem;
        font-weight: 700;
        // color: #fff;
        color: #2d4890;
      }
      .selection-content {
        // color: #fff;
        color: black;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin: 4rem 0;
        .selection-content-item {
          display: flex;
          gap: 2rem;
          align-items: center;

          .icon {
            background: #fff;
            border: 1px solid #3a3a3c;
            border-radius: 20px;
            backdrop-filter: blur(10px);
            padding: 1rem;
          }
          .links{
            text-decoration: none;
            color: black;
          }
          .links:hover{
            text-decoration: none !important; 
            color: black;
            transform:  translateX(10px) scale(1.05);
          }
        }
      }
    }
    .image-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 22rem;
      margin-top: 5%;
      .selection-image {
        display: flex;
        flex-direction: column;
        z-index: 1;
        gap: 0rem;
        .img2-3 {
          gap: 1rem;
          display: flex;
          flex-direction: row;
        }
      }
      .selection-bg-img {
        img {
          position: absolute;
          right: 0;
          width: auto;
          height: auto;
        }
      }
    }
  }
}

/* Styling for the stock card */
.stock-card {
  width: 16rem;
  // height: 20%;  
    margin-top: 8px;
    align-items: center;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 54px 55px rgba(0, 0, 0, .25), 0 -12px 30px rgba(0, 0, 0, .12), 0 4px 6px rgba(0, 0, 0, .12), 0 12px 13px rgba(0, 0, 0, .17), 0 -3px 5px rgba(0, 0, 0, .09);
    display: flex;
    flex-direction: column;
    height: 20%;
    justify-content: space-between;
    padding: 10px;
}

/* Styling for the stock title */
.stock-title {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  padding: 0;
  color: black;
  text-align: center;
}

/* Divider */
.stock-divider {
  width: 90%;
  border: none;
  border-top: 1px solid #0a0a0a;
  margin-top: 9px;
  margin-bottom: 15px;
}

/* Flex container for stock data */
.stock-data {
  display: flex;
  gap: 15px;
  justify-content: space-around;
  width: 88%;
}

/* Styling for individual stock info sections */
.stock-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Styling for stock values */
.stock-value {
  font-size: 14px;
  font-weight: 400;
  padding: 5px 15px;
  border-radius: 20px;
  border: 1px solid #e0e0e0;
  background-color: #f4f8f7;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 7rem;
}

/* Label for each value */
.stock-label {
  text-align: center;
  font-size: 14px;
  // color: #4caf50;
  margin-top: 4px;
}

/* Color classes */
.positive {
  color: #4caf50; /* Green for positive values */
}
.yellow-text{
  color: #d4df74; /* Green for positive values */
}

.negative {
  color: #f44336; /* Red for negative values */
}
.row-single-card {
  margin-left: 14rem;
  z-index: 1;
  margin-top: -2rem;
}

.row-double-card {
  margin-right: 9rem;
  z-index: 1;
  margin-top: -2rem;
}

