@font-face {
    font-family: OpenSans;
    src: url(../../../assets/fonts/OpenSans-Regular.ttf);
}

@font-face {
    font-family: OpenSansBold;
    src: url(../../../assets/fonts/OpenSans-Bold.ttf);
}


#fixTableHead {
    overflow: scroll;
    max-height: 300px;
    width: 100%;
    font-family: OpenSans;
    font-family: "Open Sans";
    font-size: 14px;


    table {
        border-collapse: collapse;
        width: 100%;
    }

    table th,
    table td {
        // max-width: 300px;
        padding: 8px 16px;
        border: 1px solid #ddd;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        //white-space: nowrap;
    }


    td:nth-child(1) {
        text-align: left;
    }



    tr:nth-child(even) {
        background-color: #f2f2f2;
    }

    table thead {
        position: sticky;
        position: -webkit-sticky;
        /* Required for Safari */
        // inset-block-start: 0;
        background-color: #ddd;
        top: 0;
        left: 0;
        transform: translate(0, -1px);

        th {
            font-family: OpenSansBold;
            font-family: "Open Sans";
            border: 1px solid white;
            background-color: #130f0f;
            color: #fff;
        }
    }

    .th-icon-text{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .name-col-th{
        display: flex;
        align-items: center;
        justify-content: flex-start;;
    }


    
}





@media only screen and (max-width: 991px) {

    #fixTableHead {
        margin-top: 10px;
        margin-bottom: 30px;
        font-size: 13px;
    }
}