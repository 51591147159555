.landing-v2 {
  // background: #000000;
  background: #eaeafa;
  scroll-behavior: smooth;
  // background-image: linear-gradient(
  //   90deg,
  //   rgba(18, 29, 58, 0.15),
  //   rgba(254, 94, 82, 0.15) 100%
  // );
}
