.table-card {
  width: 100%;
  box-shadow: 0 0px 18px rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
}

.dashboard table td button {
  color: black !important;
  text-align: center !important;
  width: 6rem !important;
  height: 2.2rem !important;
  min-width: 0%;
}
.table-header-height {
  height: 5rem;
}

.main-div {
  border-radius: 2rem;
  box-shadow: 0 0px 18px rgba(0, 0, 0, 0.2);
  .table-head-column{
    position: sticky;
    top: 0%;
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.2);
  }
  .inside-div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    justify-content: center;
    width: -webkit-fill-available;
    gap: 10px;
    margin-bottom: 1.5rem;
  }
}

.dashboard table th {
  font-weight: 600;
  color: black;
}

.custom-popover {
  background-color: rgba(0, 0, 0) !important; /* Transparent black background */
  color: white; /* White text by default */
  border-radius: 5px; /* Optional: for rounded corners */
}

.custom-popover .popover-body {
  color: white; /* Ensures all text is white by default */
}

.custom-popover .title {
  color: white; /* Title color */
  font-size: 1rem; /* Title font size */
}

.custom-popover .title span {
  font-weight: bold; /* Make 'High'/'Low'/'Weak'/'Strong' bold */
}

.custom-popover .title span[style="color: green"] {
  color: green !important; /* Green color for 'High'/'Strong' */
}

.custom-popover .title span[style="color: red"] {
  color: red !important; /* Red color for 'Low'/'Weak' */
}


@media screen and (max-width: 768px) {
  .main-div {
    .inside-div {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: center;
      width: -webkit-fill-available;
      gap: 10px;
      margin-bottom: 1.5rem;
    }
  }

  .inside-div {
    display: flex;
    justify-content: space-between;
    width: -webkit-fill-available;
    gap: 17px;
  }
  .dashboard table th {
    font-size: 12px;
    font-weight: 700;
    color: black;
  }
  .table-header-height {
    height: 3rem;
  }
}
@media screen and (max-width: 464px) {
  .dashboard table td {
    font-size: 9px !important;
  }
  .main-div {
    flex-direction: column;
  }
  .inside-div {
    display: flex;
    justify-content: space-between;
    width: -webkit-fill-available;
    gap: 17px;
  }
  .dashboard table td button {
    color: black !important;
    text-align: center !important;
    width: 4rem !important;
    height: 1.5rem !important;
    min-width: 0%;
    line-height: 0%;
    font-size: 9px;
  }
  .dashboard table th {
    font-weight: 700;
    color: black;
    font-size: 10px;
  }
  .table-header-height {
    height: 5rem;
  }
}
