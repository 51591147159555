.react-datepicker {
    //desktop
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 500;
}

.react-datepicker-wrapper {
    //mobile
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 500;
}

.react-datepicker__input-container input {
    padding: 7px 15px;
    padding-right: 35px;
    border: 1px solid rgb(199, 198, 198) !important;
    border-radius: 5px;
    width: 100%;
}


.react-datepicker__input-container input:focus {
    outline: none;
}

// .react-datepicker__close-icon {
//     top: -10px !important;
// }

.react-datepicker__close-icon::after {
    background-color: rgb(161, 159, 159);
    opacity: 0.9;
    color: #fff;

}


.react-datepicker__day--in-range {
    border-radius: 0.3em;
    background-color: #07329e;
    color: #fff;
}


.react-datepicker__day--selected{ //on select and searching new
    background-color: #0a3bb6;
    color: #fff;
}

.react-datepicker__day--in-range:hover, .react-datepicker__day--in-range:focus {
    background-color: #0a3bb6;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
 .react-datepicker__month-text--in-range, 
 .react-datepicker__quarter-text--in-range, 
 .react-datepicker__year-text--in-range){
    background-color: rgb(7, 50, 158, 0.5);
}

.react-datepicker__day--keyboard-selected,
 .react-datepicker__month-text--keyboard-selected, 
 .react-datepicker__quarter-text--keyboard-selected, 
 .react-datepicker__year-text--keyboard-selected {  //default value
    border-radius: 0.3em;
    background-color: #0a3bb6;
    color: #fff;
}


.react-datepicker__day--keyboard-selected:hover,
 .react-datepicker__month-text--keyboard-selected:hover, 
 .react-datepicker__quarter-text--keyboard-selected:hover, 
 .react-datepicker__year-text--keyboard-selected:hover {  //default value
    border-radius: 0.3em;
    background-color: #1b4ecf;
    color: #fff;
}



@media only screen and (max-width: 991px) {
    .react-datepicker {
        //desktop
        font-size: 13px;

    }

    .react-datepicker-wrapper {
        //mobile
        font-size: 13px;
    }
}


@media only screen and (max-width: 394px) {


    .react-datepicker__month-container {
        float: none;
    }
}



