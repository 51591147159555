

.Toastify__toast-body {
    // --toastify-font-family: "Open Sans";
    // --toastify-font-size: 14px !important;
    font-family: "Open Sans";
    font-size: 14px !important;

}




/// ----------- date-range-cards starts -------------------


// .date-range-cards-flex-box{
//     display: flex;
//     justify-content: flex-end;
//     align-items: center;
// }


// @media only screen and (max-width: 640px) {
    
//     .date-range-cards-flex-box {
//             display: flex;
//             flex-direction: column;
//             justify-content: center;
//             align-items: center;
//         }
    
// }

/// ----------- date-range-cards ends -------------------





/// ----------- graphs common css starts -------------------
.col-margin{
    margin-bottom: 10px;
    .outer-wraper{
        padding: 10px;
        padding-bottom: 20px;
        border-radius: 6px;
        background: #fff;
       
        .graph-text-heading{
            text-align: center;
            font-weight: 500;
            font-family: "Open Sans";
            font-size: 14px;
           
        }

        .graph-container{
            min-height: 300px;
            max-height: 300px; //overwriiten inside canvas of graph
            width: 100%;
        }
    }

}


@media only screen and (max-width: 991px) {
     .col-margin{
        margin-bottom: 20px;
       
        .outer-wraper  .graph-text-heading{
            font-size: 13px;
        }
       
    }
  }


  @media only screen and (max-width: 1400px) {
      .col-margin .outer-wraper .graph-container {
        min-height: 200px;
          max-height: 200px;
      }

  }



  /// ----------- graphs common css ends -------------------



  //spinner css
//   .loader {
//     display: flex;
//     line-height: normal;
//     vertical-align: middle;
//     align-items: center;
//     justify-content: center;
//     position: absolute;
//     top: 50%;
//     width: 95%;
//   }
  