.main-container {
   display: flex;
   justify-content: center;
   align-items: flex-start; /* Aligns the popup at the top */
   padding-top: 3rem; /* Adjusts padding to the top */
   height: 100vh;
   background-color: #f5f5f5;
   font-family: 'Arial', sans-serif;
 
   .sub-container {
     text-align: center;
     padding: 4rem;
     background-color: #fff;
     border-radius: 10px;
     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
     max-width: 500px; /* Limit the width of the popup */
     margin-top: 2rem; /* Add margin at the top for better spacing */
   }
 
   h2 {
     margin-bottom: 20px;
     color: #333;
   }
 
   .button {
     padding: 10px 20px;
     background-color: #007bff;
     color: #fff;
     border: none;
     border-radius: 5px;
     cursor: pointer;
     transition: background-color 0.3s ease;
     
     &:hover {
       background-color: #0056b3;
     }
 
     &:disabled {
       background-color: #ccc; /* Grey out the button when disabled */
       cursor: not-allowed; /* Change the cursor to not-allowed */
     }
   }
 
   .animate-spin {
     animation: spin 2s linear infinite;
   }
 
   @keyframes spin {
     0% {
       transform: rotate(0deg);
     }
     100% {
       transform: rotate(360deg);
     }
   }
 }
 

/* ProtectedHOC.scss */
.modal-overlay {
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background-color: rgba(0, 0, 0, 0.75); /* Darker background */
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 1000; /* Ensures it overlays above other content */
 }
 
 .modal-content1 {
   background-color: white;
   padding: 20px 25px !important;
   border-radius: 8px;
   box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3); /* Keep existing card design */
   max-width: 500px;
   width: 100%;
   text-align: center;
   z-index: 1100; /* Ensure the card is above the overlay */
 }
 
 .blurred-content {
   filter: blur(5px); /* Adjust blur intensity */
   pointer-events: none; /* Prevent interaction with blurred content */
   opacity: 0.5;
 }
 
 .btn1 {
  padding: 10px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #ccc; /* Grey out the button when disabled */
    cursor: not-allowed; /* Change the cursor to not-allowed */
  }
}