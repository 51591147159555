.only-desktop {
    display: none;
}


@media screen and (min-width: 768px) {
    .only-desktop {
        display: initial;
    }
    .only-mobile {
        display: none;
    }
    
}