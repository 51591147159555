.analyzed-tweet {
  .inner-content {
    background-color: #fbfbfb;
    border-radius: 6px;

    // max-height: 75vh;
    // min-height: 75vh;
    h6 {
      font-family: "Open Sans";
      //font-weight: 500;
      font-weight: bold;
      color: black;
    }

    span {
      font-size: 15px;
      font-weight: 500;
      color: #575c5c;
    }

    .header {
      display: flex;
      justify-content: space-around;
    }
  }

  .max-height-80 {
    min-height: 80%;
  }

  .background-white {
    background-color: white;
    border-radius: 5px;
    cursor: pointer;

    .statusDots {
      height: 17px;
      width: 17px;
      border-radius: 50px;
    }

    p.no-tweets {
      font-size: 15px;
    }

    .time {
      color: #9fa6a7;
      font-size: 15px;
      font-weight: 500;
      font-family: "Open Sans";
    }

    .mint-text {
      font-size: 15px;
      font-weight: 500;
      font-family: "Open Sans";
      line-height: 25px;

      span {
        font-weight: 900;
      }
    }
  }

  .insider-child {
    button {
      background: #cdd9f9;
      color: black;
      font-weight: 500;
      border-radius: 7px;
    }

    position: relative;
    overflow-y: scroll;
    // -ms-overflow-style: none; /* IE and Edge */
    // scrollbar-width: none; /* Firefox */
    // &::-webkit-scrollbar {
    //   display: none;
    // }

    scrollbar-color: rgb(57, 224, 164) transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgb(57, 224, 164);
      border-radius: 20px;
      border: 0px solid transparent;
    }

    .scroll-button {
      background: #3956a3;
      border-radius: 50px;
      height: 43px;
      max-width: 43px;
      min-width: 43px;
      position: absolute;
      bottom: 15px;
      right: 15px;
    }
  }
}


@media only screen and (max-width: 991px) {

  .analyzed-tweet .inner-content h6 {
    font-size: small;
  }

  .analyzed-tweet .background-white p.no-tweets {
    font-size: 14px;
  }

}