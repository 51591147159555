@font-face {
    font-family: OpenSans;
    src: url(../../../assets/fonts/OpenSans-Regular.ttf);
}


.ant-select-selection-item {
    font-size: 15px !important;
    font-family: OpenSans !important;
    //font-weight: 500 !important;
    //color: MediumPurple !important;
}


.ant-select-item-option-content {
    font-size: 15px !important;
    font-family: OpenSans !important;
}

//clear  icon 
.ant-select .ant-select-clear {
    top: 30%;
    font-size: 18px;
    width: 18px;
    height: 18px;
}

//no results found
.ant-select-item-empty p{
    font-size: 15px !important;
    font-family: OpenSans !important;
}

// .ant-select-selector{
// outline: none !important;
// height: 500px !important;
// }

@media only screen and (max-width: 991px) {
    .ant-select-selection-item {
        font-size: 14px !important;
    }


    .ant-select-item-option-content {
        font-size: 14px !important;
    }


    .ant-select-item-empty p{
        font-size: 14px !important;
    }

}



/* searchBar.scss */
.custom-search-dashboard {
    background: #FFFCFC;
    padding: 2px 0;
    border-radius: 4px;
    border: 1px solid black;
  
    .ant-select {
      width: 550px !important;
      height: 50px !important;
  
      .ant-select-selector {
        // background: hsl(0, 100%, 99%) !important;
        // border: 1px solid black !important;
        border-radius: 4px !important;
        // padding: 2px !important;
  
        .ant-select-selection-search-input {
        //   background: #FFFCFC !important;
        }
      }
  
      .ant-select-arrow {
        color: black !important;
      }
    }
  }
  