// General styles for the NewStatergy component
.tab-container {
  padding: 2rem 3rem;
  display: flex;
  margin: 1rem 0;
  gap: 1rem;
  border-bottom: none;

  .tab {
    padding: 1rem;
    border: 1px solid #062880;
    border-radius: 10px;
  }
  .tab:hover {
    background-color: #062880;
    color: white;
    border-bottom: #062880;
  }
  .tab-active {
    padding: 1rem;
    border: 1px solid #062880;
    border-radius: 10px;
    background-color: #062880;
    color: white;
  }
}
.info-line{
  display: flex;
  margin: 1rem 4rem;
  justify-content: end;
  color:red;
  font-style:italic;
}
.month-title{
  margin: 1rem 0 1rem 0 ;
  text-align: center;
  color: currentColor;
  font-weight: bold;
  font-size: 30px;
}
.forwardTesting-title{
  margin: 1rem 0 1rem 0 ;
  text-align: center;
  color: currentColor;
  font-weight: bold;
  font-size: 30px;

}
.subtext{
  padding: 1rem 6rem;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  margin: 1rem 14rem;
}
.heatmap{
  .title{
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    margin: 1rem 0;
  }
}
.portfolio-section{
  padding: 1rem;
  h1 {
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    margin: 1rem 0;
  }
  .text-image-split{
      justify-content :center ;
      display: flex;
      // flex-direction: column;
      gap: 1rem;
      padding: 1rem 5rem;
      align-items: center;
      .text{
          display: flex;
          flex-direction: column;
          gap: 1rem;
          text-align: center;
          padding: 1rem;
          // padding:0rem 5rem;
          h5{
              text-align: justify;
              font-size: 1.25rem;
              font-weight: 700;
          }
      }
      .historical-performance-table{
        .table {
          width: 100%; // Full width table
          border-collapse: collapse; // Remove space between cells
          // border: 2px solid #062880; // Blue border around table
          border-radius: 15px; // Rounded corners for table
          box-shadow: 0 0 10px rgba(0,0,0,0.1);
        
          &.table-striped {
            th{
              padding: 15px 35px; // Padding inside cells
              text-align: center; // Align text to the left
              border-bottom: 1px solid #ddd; // Bottom border for separation
        
              &:first-child {
                border-top-left-radius: 15px; // Rounded corners for first cell in header
              }
        
              &:last-child {
                border-top-right-radius: 15px; // Rounded corners for last cell in header
              }
            }
        
            th {
              font-size: 15px;
              background-color: #062880;
              color: white; // White text for contrast
              font-weight: bold; // Bold font for headers
            }
        
            td {
              font-size: 15px;
              vertical-align: top; // Align content to top of cell
              padding: 15px 35px; // Padding inside cells
              text-align: center; // Align text to the left
              border-bottom: 1px solid #ddd; // Bottom border for separation
            }
          }
        }
      }
  }
}
.analysis {
  margin: 2rem 5%;
  .title {
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    margin: 1rem 0;
  }
  .analysis-filters {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 2rem;
    padding: 1rem 0;
    margin: 3rem 5rem;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    .filter {
      padding: 0.5rem 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;

      label {
        font-size: 1.25rem;
        font-weight: 500;
      }
      select {
        padding: 0.5rem;
        border: 1px solid #2d4890;
        border-radius: 5px;
        background-color: #fff;
      }
    }
    .month-year-div {
      display: flex;
    }
  }
  .line-graph {
    display: flex !important;
    justify-content: center !important;
    flex-direction: column !important;
  
  }
}
.color-legend {
  margin-bottom: 20px;
  display: flex;
  justify-content:space-between;
  flex-direction: row;

  .main-legend{
    display: flex;
    gap: 1rem;
  }

  p {
    margin: 0 0 10px 0;
    display: flex;
    align-items: center;

    .legend-box {
      width: 15px;
      height: 15px;
      margin-right: 10px;
      display: inline-block;

      &.green {
        background-color: rgba(255, 204, 92,0.7);
        border:1px solid black;
      }

      &.blue {
        background-color: rgba(255, 204, 92,0.5);
        border:1px solid black;
      }

      &.yellow {
        background-color: rgba(255, 204, 92,0.3);
        border:1px solid black;
      }

      &.red {
        background-color: rgba(255, 204, 92,0.1);
        border:1px solid black;
      }
    }
  }
  p:hover{
    cursor: pointer;
    font-weight: 600;
  }
  .update-line{
    font-weight:600;
    font-style:italic;
  }
}
// Conditional row colors based on the given conditions
//rgba for yellow color
//rgba(6, 40, 128, 0.3)
.green-row {
  background-color: rgba(255, 204, 92,0.7);
}

.blue-row {
  background-color:  rgba(255, 204, 92,0.5); 
}

.yellow-row {
  background-color: rgba(255, 204, 92,0.3); 
}

.red-row {
  background-color: rgba(255, 204, 92,0.1); 
}
.new-statergy-container,
.momentum-value-table {
  padding: 50px;

  h1 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
  }

  .table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15); // Add shadow to the container
    border-radius: 15px; // Rounded corners for the container

    th,
    td {
      padding: 12px 15px;
      border: 2px solid #ddd;
      text-align: center;
      font-size: 16px;
      color:black;
      font-weight:500;
    }

    th {
        background-color: rgba(245, 245, 245, 0.721);
        color: black;
        font-weight: 700;
      //   text-transform: uppercase;
    }
    th:first-child {
      border-top-left-radius: 20px;
    }

    th:last-child {
      border-top-right-radius: 20px;
    }

    tbody tr {
      transition: background-color 0.3s ease;
    }
    tr:last-child td:first-child {
      border-bottom-left-radius: 20px;
    }
    tr:last-child td:last-child {
      border-bottom-right-radius: 20px;
    }
  }
}
.new-statergy-container,.new-value-statergy-container{
  padding-bottom: 0;
  .recompute-section{
    display: flex;
    justify-content: flex-start;
    padding: 0.5rem;
    .recompute-button{
      padding: 0.5rem;
      background-color: #062880;
      color: white;
      border: none;
      border-radius: 5px;
      font-size: 1rem;
      font-weight: 600;
      cursor: pointer;
    }
    .recompute-button:disabled{
      background-color: #9cb0dd;
      cursor: not-allowed;
    }
  }
}
.new-value-statergy-container {
  padding: 2rem;
  max-height: 80vh;
  overflow: scroll;
  margin: 1rem 1rem;

  .table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15); // Add shadow to the container
    border-radius: 15px; // Rounded corners for the container

    th,
    td {
      padding: 12px 15px;
      border: 2px solid #ddd;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
    }

    td {
      padding: 0.5rem;
      //width of column
    }
    .hover-columns {
      width: 15%;
      // want to truncate the the text in the column and show full when we hover
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 0;
    }
    .hover-columns:hover {
      max-width: 100vw;
      overflow: visible;
      white-space: normal;
    }

    th {
      background-color:whitesmoke;
      color: black;
      font-weight: 700;
      //   text-transform: uppercase;
    }
    th:first-child {
      border-top-left-radius: 20px;
    }

    th:last-child {
      border-top-right-radius: 20px;
    }

    tbody tr {
      transition: background-color 0.3s ease;
    }
    tr:last-child td:first-child {
      border-bottom-left-radius: 20px;
    }
    tr:last-child td:last-child {
      border-bottom-right-radius: 20px;
    }
  }
}

.plot-table-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  gap:5rem;
  
  .chart-img{
    background: #fff;
    padding: 1rem;
    border-radius: 15px;
  }
}
.momentum-value-plot {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15); // Add shadow to the container
  background-color: white;
  border-radius: 15px; // Rounded corners for the container
}

.table-momentum-strategy{
  position: relative;

    .loading-overlay{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;

      .spinner-border{
        width: 3rem;
        height: 3rem;
      }

      .loading-text{
        margin-left: 1rem;
      }
    }
}

// Responsive styles for smaller screens
@media (max-width: 768px) {
  .subtext{
    padding: 1rem 1rem ;
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    margin: 1rem 1rem;
  }
  .momentum-value-plot{
    width: 300px !important;
  }
  .plot-table-container{
    display: flex;
    flex-direction: column;
  }
  .img-strategy{
    width: 300px;
    height: 400px;
  }
  .color-legend{
    display: flex !important;
    flex-direction: column !important;
  }
  .table-momentum-strategy{
    overflow-x: scroll;
  }
  .new-statergy-container {
    padding: 10px;

    h1 {
      font-size: 20px;
    }

    .table {
      font-size: 12px;

      th,
      td {
        padding: 8px;
      }
    }
  }

}