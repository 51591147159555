/* Center the container on the page */
.reset-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f7fc;
  }
  
  /* Styling for the box containing the form */
  .reset-password-box {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%;
    max-width: 400px;
  }
  
  /* Logo styling */
  .logo {
    width: 100px;
    margin-bottom: 20px;
  }
  
  /* Form heading */
  .reset-password-box h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Form group - inputs and labels */
  .form-group {
    margin-bottom: 20px;
    text-align: left;
  }
  
  /* Form labels */
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
    font-size: 14px;
    color: #333;
  }
  
  /* Input fields */
  .form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    color: #333;
    background-color: #f9f9f9;
    transition: border-color 0.3s ease-in-out;
  }
  
  /* Input focus effect */
  .form-group input:focus {
    border-color: #007bff;
    outline: none;
  }
  
  /* Reset password button */
  .reset-password-btn {
    background-color: #007bff;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    width: 100%;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .reset-password-btn:hover {
    background-color: #0056b3;
  }
  
  /* Message styling */
  .success-message {
    color: green;
    font-weight: bold;
    margin-top: 10px;
  }
  
  .error-message {
    color: red;
    font-weight: bold;
    margin-top: 10px;
  }
  .logo-img{
    width: 11rem;
    height: 4rem;
  }

  .password-wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .password-wrapper input {
    width: 100%;
    padding-right: 40px; 
  }
  
  .toggle-password-btn {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
  }
  
  .toggle-password-btn svg {
    font-size: 18px;
    color: #888;
  }
  